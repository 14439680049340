var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DataProcessing from '@/components/SettingsView/DataProcessing';
import GeneralSettings from '@/components/SettingsView/GeneralSettings';
import PlanSettings from '@/components/SettingsView/PlanSettings';
import DataProcessingSettings from '@/components/SettingsView/DataProcessingSettings';
import UserManager from '@/components/SettingsView/UserManager';
import ViewsFieldsSettings from '@/components/SettingsView/ViewsFieldsSettings';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { hasAccess, requiresFintaryAdmin } from '@/services/helpers';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
import AgentSettings from '@/components/SettingsView/AgentSettings';
import CompReportSettings from '@/components/SettingsView/CompReportSettings';
import ApiKeySettings from '@/components/SettingsView/ApiKeySettings';
import DataUpdateTools from '@/components/ToolsPage/DataUpdateTools/DataUpdateTools';
import AccountConfigsSettings from '@/components/SettingsView/AccountConfigs';
const TabPanel = (_a) => {
    var { children, value, index } = _a, other = __rest(_a, ["children", "value", "index"]);
    return (_jsx("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `tabpanel-${index}` }, other, { children: value === index && (_jsx(Box, { sx: { pt: 2 }, children: _jsx(Typography, { component: "div", children: children }) })) })));
};
const tabs = [
    {
        label: 'General',
        path: 'general',
        component: _jsx(GeneralSettings, {}),
    },
    {
        label: 'User manager',
        path: 'user-manager',
        component: _jsx(UserManager, {}),
        access: [Roles.ACCOUNT_ADMIN],
    },
    {
        label: 'Data processing',
        path: 'data-processing',
        component: _jsx(DataProcessing, {}),
        access: [Roles.ACCOUNT_ADMIN],
    },
    {
        label: 'Plan',
        path: 'plan',
        component: _jsx(PlanSettings, {}),
        access: [Roles.ACCOUNT_ADMIN],
    },
    {
        label: 'Views & fields',
        path: 'views',
        component: _jsx(ViewsFieldsSettings, {}),
        access: [Roles.FINTARY_ADMIN],
    },
    {
        label: 'Data settings',
        path: 'data-processing-settings',
        component: _jsx(DataProcessingSettings, {}),
        access: [Roles.FINTARY_ADMIN],
    },
    {
        label: 'Agent settings',
        path: 'agent-settings',
        component: _jsx(AgentSettings, {}),
        access: [Roles.FINTARY_ADMIN],
    },
    {
        label: 'Comp report settings',
        path: 'comp-report-settings',
        component: _jsx(CompReportSettings, {}),
        access: [Roles.FINTARY_ADMIN],
    },
    {
        label: 'API keys',
        path: 'api-keys',
        component: _jsx(ApiKeySettings, {}),
        access: [Roles.ACCOUNT_ADMIN],
    },
    {
        label: 'Data actions',
        path: 'data-update-tools',
        component: _jsx(DataUpdateTools, {}),
        access: [Roles.FINTARY_ADMIN],
    },
    {
        label: 'Account configs',
        path: 'account-configs',
        component: _jsx(AccountConfigsSettings, {}),
        access: [Roles.FINTARY_ADMIN],
    },
];
const SettingsView = () => {
    // User Roles and Fintary Admin have different implementations at the moment
    // Currently, role === 'admin' for Fintary Admin,
    // while userRole
    const { userRole } = useRoleStore();
    const { role: [role], } = useContext(UIStateContext);
    const filteredTabs = tabs.filter((tab) => hasAccess(tab.access, userRole, role === 'admin'));
    const { tab = 'general' } = useParams();
    const tabPaths = filteredTabs.map((tab) => tab.path);
    const routedTab = tabPaths.indexOf(tab) >= 0 ? tabPaths.indexOf(tab) : 0;
    const [value, setValue] = useState(routedTab);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        navigate(`/settings/${filteredTabs[newValue].path}`);
        setValue(newValue);
    };
    useEffect(() => {
        if (value !== tabPaths.indexOf(tab) && tabPaths.indexOf(tab) >= 0)
            setValue(tabPaths.indexOf(tab));
    }, [role, tab, tabPaths, routedTab]);
    return (_jsx(Box, { sx: { width: 1, overflowY: 'scroll' }, children: _jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: 'divider', mt: 2, mx: 2 }, children: _jsx(Tabs, { value: value, onChange: handleChange, children: filteredTabs.map((tab) => (_jsx(Tab, { label: `${tab.label}${requiresFintaryAdmin(tab.access) ? ' 🔒' : ''}` }, tab.label))) }) }), filteredTabs.map((tab) => (_jsx(TabPanel, { value: value, index: filteredTabs.indexOf(tab), children: tab.component }, tab.label)))] }) }));
};
export default SettingsView;
