import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash-es';
import currency from 'currency.js';
import { AgGridReact } from 'ag-grid-react';
import { Clear, FileDownload, InfoOutlined, Search } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Tooltip, InputAdornment, TextField, } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { tool } from 'common/tools';
import { exportCsv } from '@/services/helpers';
import AddFieldsDialog from './addFieldDialog';
import { normalizeCurrency } from '@/services/DataTransformation/normalizer';
import { ProcessMethodE, ProcessResultE, } from './process';
import RemoveFieldsDialog from './removeFieldDialog';
import VertexResult from './VertexResult';
import { DocumentProcessActionTypes } from '@/types';
import useCommissionStore from './stores/useCommissionStore';
dayjs.extend(utc);
const CommissionPreview = ({ processedData, setProcessedData, shareData, setProcessFormatData, handleProcessFormChange, setErrors, missingRequiredFields, addActionCount, rowData, }) => {
    var _a, _b;
    const rowMapping = useCommissionStore((state) => state.rowMapping);
    const setRowMapping = useCommissionStore((state) => state.setRowMapping);
    const [columnDefs, setColumnDefs] = useState([]);
    const [customData, setCustomData] = useState([]);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);
    const [removeNode, setRemoveNode] = useState();
    const [rowDataDef, setRowDataDef] = useState([]);
    const [open, setOpen] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [canAddFields, setCanAddFields] = useState([]);
    const [canRemoveFields, setCanRemoveFields] = useState([]);
    const [showVertexRes, setShowVertexRes] = useState(false);
    const [invalidVertexRes, setInvalidVertexRes] = useState('');
    const [parsedVertexJson, setParsedVertexJson] = useState([]);
    const [resultTableSearch, setResultTableSearch] = useState('');
    const gridRef = useRef(null);
    const onConfirmVertexRes = (jsonStr) => {
        if (!jsonStr)
            return;
        try {
            const res = JSON.parse(jsonStr);
            setParsedVertexJson(res);
            addActionCount(DocumentProcessActionTypes.FIX_EXTRACTION);
            // Update the extractions data
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setShowVertexRes(false);
        }
    };
    useEffect(() => {
        var _a, _b;
        if (((_a = shareData.fileData) === null || _a === void 0 ? void 0 : _a.type) === ProcessMethodE.InvalidJSON) {
            setInvalidVertexRes((_b = shareData.fileData) === null || _b === void 0 ? void 0 : _b.data);
            setShowVertexRes(true);
        }
    }, [shareData.fileData]);
    useEffect(() => {
        if (parsedVertexJson && parsedVertexJson.length) {
            const { keys, values } = tool.convertMapToArray(parsedVertexJson);
            setProcessedData({
                data: values,
                fields: keys,
                version: ProcessResultE.Gemini,
            });
        }
    }, [parsedVertexJson]);
    useEffect(() => {
        var _a;
        let data = [];
        if (processedData && processedData.version && ((_a = processedData.data) === null || _a === void 0 ? void 0 : _a.length)) {
            data = [...processedData.data];
            if (processedData.sheet) {
                handleProcessFormChange('selectedSheet', processedData.sheet);
            }
        }
        if (data.length) {
            setCustomData(data);
        }
    }, [processedData]);
    /**
     * Get processed data by mapping and custom data (closely like the data in the table)
     */
    useEffect(() => {
        const _keys = Object.keys(rowMapping);
        if (_keys.length && customData.length && shareData.fields) {
            const mapingEntries = Object.entries(rowMapping);
            const rowList = customData.map((item) => {
                const row = mapingEntries.reduce((acc, [k, v]) => {
                    var _a;
                    // Format value
                    let formattedValue;
                    const targetField = ((_a = shareData.fields) === null || _a === void 0 ? void 0 : _a[k]) || {};
                    const index = typeof v === 'object' ? v.colIndex : v;
                    if (typeof v === 'string') {
                        formattedValue = v;
                    }
                    else if (targetField.formatter) {
                        formattedValue = targetField.formatter(item[index]);
                    }
                    else {
                        formattedValue = item[index];
                    }
                    acc[k] = formattedValue;
                    return acc;
                }, {});
                return row;
            });
            setRowDataDef(rowList);
        }
        else if (!customData.length || !_keys.length) {
            setRowDataDef([]);
        }
    }, [customData, rowMapping, shareData.fields]);
    /**
     * Check if missing required fields
     */
    useEffect(() => {
        const tips = missingRequiredFields.length
            ? `Missing required fields: ${missingRequiredFields.join(', ')}`
            : undefined;
        setErrors((error) => {
            return Object.assign(Object.assign({}, error), { missingField: tips });
        });
    }, [missingRequiredFields]);
    const getTotalRowItem = () => {
        if (!rowDataDef.length || !(shareData === null || shareData === void 0 ? void 0 : shareData.fields))
            return;
        const mappingKeys = Object.keys(rowMapping);
        const amtKeys = Object.entries(shareData.fields)
            .map(([k, v]) => {
            return v.type === 'currency' && mappingKeys.includes(k) ? k : null;
        })
            .filter(Boolean);
        const totalRowItem = {};
        amtKeys.forEach((k) => {
            const total = rowDataDef.reduce((acc, cur) => {
                var _a;
                if (!cur[k] || ((_a = cur[k]) === null || _a === void 0 ? void 0 : _a.toString().includes('❌')))
                    return acc;
                const calcVal = currency(acc).add(cur[k]).format();
                return calcVal;
            }, 0);
            totalRowItem[k] = total;
        });
        return totalRowItem;
    };
    useEffect(() => {
        const totalRowItem = getTotalRowItem();
        setPinnedBottomRowData([totalRowItem]);
    }, [rowDataDef, shareData === null || shareData === void 0 ? void 0 : shareData.fields, rowMapping]);
    useEffect(() => {
        if (!rowDataDef.length)
            return;
        setProcessFormatData((prev) => {
            return Object.assign(Object.assign({}, prev), { rowData: rowDataDef, mappingOptions: rowMapping });
        });
    }, [rowDataDef]);
    useEffect(() => {
        if (pinnedBottomRowData.length) {
            setProcessFormatData((prev) => {
                var _a;
                return Object.assign(Object.assign({}, prev), { cmsTotal: normalizeCurrency(((_a = pinnedBottomRowData[0]) === null || _a === void 0 ? void 0 : _a.commission_amount) || '') });
            });
        }
    }, [pinnedBottomRowData]);
    const dataTypeDefinitions = useMemo(() => {
        return {
            dateString: {
                baseDataType: 'dateString',
                extendsDataType: 'dateString',
                valueParser: (params) => params.newValue != null &&
                    params.newValue.match('\\d{2}/\\d{2}/\\d{4}')
                    ? params.newValue
                    : null,
                valueFormatter: (params) => (params.value == null ? '' : params.value),
                dataTypeMatcher: (value) => typeof value === 'string' && !!value.match('\\d{2}/\\d{2}/\\d{4}'),
                dateParser: (value) => {
                    if (value == null || value === '') {
                        return undefined;
                    }
                    const dateParts = value.split('/');
                    return dateParts.length === 3
                        ? dayjs.utc(new Date(value)).toDate()
                        : undefined;
                },
                dateFormatter: (value) => {
                    if (value == null) {
                        return undefined;
                    }
                    const date = String(value.getDate());
                    const month = String(value.getMonth() + 1);
                    return `${month.length === 1 ? '0' + month : month}/${date.length === 1 ? '0' + date : date}/${value.getFullYear()}`;
                },
            },
        };
    }, []);
    // Load table column by mapping
    useEffect(() => {
        var _a, _b;
        const mappings = ((_a = shareData.fileData) === null || _a === void 0 ? void 0 : _a.type) === 'GPT'
            ? (_b = shareData.fileData) === null || _b === void 0 ? void 0 : _b.data[0]
            : rowMapping;
        const _keys = Object.keys(mappings);
        if (_keys.length) {
            const _columnDefs = _keys.map((k) => {
                var _a;
                const targetField = ((_a = shareData.fields) === null || _a === void 0 ? void 0 : _a[k]) || {};
                const baseConf = {
                    headerName: targetField.label || k,
                    field: k,
                    minWidth: 160,
                    editable: (params) => {
                        return !params.node.rowPinned;
                    },
                    filter: true,
                    resizable: true,
                    valueParser: (params) => {
                        if (targetField.formatter && targetField.type !== 'date') {
                            return targetField.formatter(params.newValue);
                        }
                        return params.newValue;
                    },
                };
                if ((targetField.type && targetField.type === 'date') ||
                    k.includes('_date')) {
                    return Object.assign(Object.assign({}, baseConf), { cellEditor: 'agDateStringCellEditor', cellEditorParams: {
                            min: '1900-01-01',
                            max: '2100-01-01',
                        } });
                }
                else {
                    return baseConf;
                }
            });
            const actionColumn = {
                headerName: '',
                field: '',
                pinned: 'left',
                width: 40,
                cellStyle: {
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                },
                cellRenderer: (params) => {
                    if (!params.node.rowPinned) {
                        return (_jsx(IconButton, { sx: {
                                ':hover': {
                                    opacity: 1,
                                    color: 'error.main',
                                },
                                opacity: 0.55,
                            }, onClick: () => {
                                setRemoveNode(params);
                            }, children: _jsx(Clear, {}) }));
                    }
                    else {
                        return (_jsx(Tooltip, { title: "Totals calculated and provided for review only. This row will not be imported.", children: _jsx(IconButton, { sx: { opacity: 0.55 }, children: _jsx(InfoOutlined, {}) }) }));
                    }
                },
            };
            setColumnDefs([actionColumn, ..._columnDefs]);
        }
        else {
            setColumnDefs([]);
        }
    }, [rowMapping, shareData.fields, shareData.fileData]);
    useEffect(() => {
        if (removeNode) {
            gridRef.current.api.applyTransaction({
                remove: [removeNode.data],
            });
            const rowIndex = removeNode.node.childIndex;
            setCustomData((prev) => {
                return prev.filter((_, index) => index !== rowIndex);
            });
            const fileteredRowData = rowDataDef.filter((_, index) => index !== rowIndex);
            setRowDataDef(fileteredRowData);
            addActionCount(DocumentProcessActionTypes.DELETE_DATA);
            setRemoveNode(undefined);
        }
    }, [removeNode, rowDataDef, addActionCount]);
    useEffect(() => {
        var _a;
        if (customData.length > 0 && ((_a = rowData.companies) === null || _a === void 0 ? void 0 : _a.company_name)) {
            updateCarrierName();
        }
    }, [customData.length, rowData.companies]);
    const onCellValueChanged = (params) => {
        addActionCount(DocumentProcessActionTypes.EDIT_DATA);
        const totalRowItem = getTotalRowItem();
        if (!isEqual(pinnedBottomRowData[0], totalRowItem)) {
            setPinnedBottomRowData([totalRowItem]);
        }
        const { value, colDef: { field }, rowIndex, } = params;
        if (field === 'action')
            return;
        if (rowIndex !== null && field) {
            const newData = [...customData];
            const allFieldCellIsEmpty = newData.every((row) => {
                const index = typeof rowMapping[field] === 'object'
                    ? rowMapping[field].colIndex
                    : rowMapping[field];
                return !row[index];
            });
            const isAddedField = typeof rowMapping[field] === 'string';
            if (isAddedField) {
                setRowMapping({
                    [field]: value,
                });
            }
            else {
                if (allFieldCellIsEmpty) {
                    newData.forEach((row) => {
                        const index = typeof rowMapping[field] === 'object'
                            ? rowMapping[field].colIndex
                            : rowMapping[field];
                        row[index] = value;
                    });
                    setCustomData(newData);
                }
                else {
                    const index = typeof rowMapping[field] === 'object'
                        ? rowMapping[field].colIndex
                        : rowMapping[field];
                    newData[rowIndex][index] = value;
                    setCustomData(newData);
                }
            }
        }
        setProcessFormatData((prev) => {
            return Object.assign(Object.assign({}, prev), { rowData: rowDataDef });
        });
    };
    const onFilterChanged = (_params) => {
        const filteredRows = getAllRows();
        setProcessFormatData((prev) => {
            return Object.assign(Object.assign({}, prev), { rowData: filteredRows });
        });
        addActionCount(DocumentProcessActionTypes.FILTER_DATA);
    };
    const gridOptions = useMemo(() => {
        return {
            defaultColDef: {
                sortable: true,
                flex: 1,
            },
            animateRows: true,
            suppressMenuHide: true,
            alwaysShowHorizontalScroll: true,
        };
    }, []);
    const addField = () => {
        const currentFields = columnDefs.map((item) => item.field).filter(Boolean);
        const _canAddFields = shareData.allFieldKeys
            .filter((item) => item.model === 'statements')
            .map((item) => {
            return !currentFields.includes(item.key) ? item : null;
        })
            .filter(Boolean);
        setCanAddFields(_canAddFields);
        setOpen(true);
    };
    const removeField = () => {
        const currentFields = columnDefs.map((item) => item.field).filter(Boolean);
        const formattedFields = currentFields
            .filter((s) => s && !s.includes('action'))
            .map((k) => {
            const target = shareData.allFieldKeys.find((item) => item.key === k);
            if (target) {
                return target;
            }
            else {
                return {
                    key: k,
                    label: k,
                    type: 'text',
                };
            }
        });
        setCanRemoveFields(formattedFields);
        setShowRemove(true);
    };
    const generateNewCustomedRowData = () => {
        const processedDataLength = processedData.fields.length;
        const newRow = Array.from({ length: processedDataLength }, () => '');
        return newRow;
    };
    const getAllRows = () => {
        if (!gridRef.current)
            return [];
        const rows = gridRef.current.api.getRenderedNodes();
        return rows.map((row) => row.data);
    };
    const addRow = () => {
        const newRowData = generateNewCustomedRowData();
        const allRows = [newRowData, ...customData];
        setCustomData(allRows);
        addActionCount(DocumentProcessActionTypes.ADD_DATA);
    };
    const onAddFields = (data) => {
        addActionCount(DocumentProcessActionTypes.ADD_DATA);
        if (!data)
            return;
        const baseConf = {
            headerName: data.label,
            field: data.key,
            minWidth: 160,
            editable: true,
            filter: true,
            resizable: true,
        };
        let option = baseConf;
        if (data.key.includes('_date') || data.type === 'date') {
            option = Object.assign(Object.assign({}, baseConf), { cellEditor: 'agDateStringCellEditor', cellEditorParams: {
                    min: '01/01/2020',
                    max: '01/01/2050',
                } });
        }
        setColumnDefs(columnDefs.concat(option));
        setRowMapping({ [data.key]: '' });
        setOpen(false);
    };
    const onRemoveFields = (data) => {
        addActionCount(DocumentProcessActionTypes.DELETE_DATA);
        if (!data.length)
            return;
        const delList = data.map((item) => item.key);
        const newColumns = columnDefs.filter((item) => {
            return !delList.includes(item.field);
        });
        setColumnDefs(newColumns);
        const newMapping = Object.assign({}, rowMapping);
        delList.forEach((k) => {
            Reflect.deleteProperty(newMapping, k);
        });
        setRowMapping(newMapping);
        setShowRemove(false);
    };
    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { background: '#F0F0F0' };
        }
    };
    const updateCarrierName = () => {
        var _a, _b;
        let carrierNameIndex;
        if (!columnDefs.some((col) => col.field === 'carrier_name')) {
            const carrierColumn = {
                headerName: 'Paying entity',
                field: 'carrier_name',
                minWidth: 160,
                editable: true,
                filter: true,
                resizable: true,
            };
            setColumnDefs((prev) => [...prev, carrierColumn]);
            carrierNameIndex = ((_a = customData[0]) === null || _a === void 0 ? void 0 : _a.length) || 0;
            setRowMapping({ carrier_name: carrierNameIndex });
        }
        else {
            const mapping = rowMapping['carrier_name'];
            if (typeof mapping === 'object' &&
                mapping !== null &&
                'colIndex' in mapping) {
                carrierNameIndex = mapping.colIndex;
            }
            else if (typeof mapping === 'number') {
                carrierNameIndex = mapping;
            }
            else {
                carrierNameIndex = ((_b = customData[0]) === null || _b === void 0 ? void 0 : _b.length) || 0;
                setRowMapping({ carrier_name: carrierNameIndex });
            }
        }
        const updatedCustomData = customData.map((row) => {
            var _a;
            if (!Array.isArray(row))
                return row;
            const newRow = [...row];
            if (newRow.length <= carrierNameIndex) {
                newRow.length = carrierNameIndex + 1;
                newRow.fill('', row.length, carrierNameIndex);
            }
            newRow[carrierNameIndex] = ((_a = rowData.companies) === null || _a === void 0 ? void 0 : _a.company_name) || '';
            return newRow;
        });
        setCustomData(updatedCustomData);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    overflow: 'auto',
                }, children: [_jsxs(Box, { sx: {
                            display: 'flex',
                            width: '100%',
                            gap: 1,
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }, children: [_jsx(TextField, { size: "small", placeholder: "Search...", onChange: (e) => setResultTableSearch(e.target.value), sx: {
                                    width: '200px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                        height: '32px',
                                    },
                                    '& .MuiInputLabel-root': {
                                        lineHeight: '1em',
                                    },
                                }, InputProps: {
                                    startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(Search, {}) })),
                                } }), rowDataDef.length ? (_jsx(Chip, { sx: {
                                    minWidth: '90px',
                                }, label: `Rows: ${(_a = gridRef === null || gridRef === void 0 ? void 0 : gridRef.current) === null || _a === void 0 ? void 0 : _a.api.getDisplayedRowCount()}` })) : (''), _jsx(Button, { variant: "outlined", onClick: addField, children: "Add field" }), _jsx(Button, { variant: "outlined", onClick: addRow, children: "Add row" }), _jsx(Button, { variant: "outlined", onClick: removeField, children: "Remove field" }), _jsx(Tooltip, { title: "Export as CSV", placement: "bottom", children: _jsx(IconButton, { onClick: () => {
                                        exportCsv(Object.keys(rowDataDef[0]).filter((k) => k !== 'action'), rowDataDef, `Fintary-Preview-Export.csv`);
                                    }, disabled: !rowDataDef[0], children: _jsx(FileDownload, { sx: { color: '#aaa' } }) }) }), _jsx(Tooltip, { title: "Double-click cell to edit", placement: "left", children: _jsx(InfoOutlined, { sx: { color: '#ccc' } }) })] }), _jsx(Box, { sx: { width: '100%', flex: 1, overflow: 'auto' }, className: "ag-theme-material", children: _jsx(AgGridReact, Object.assign({ ref: gridRef, headerHeight: 40, rowData: rowDataDef, columnDefs: columnDefs, onCellValueChanged: onCellValueChanged, onFilterChanged: onFilterChanged, dataTypeDefinitions: dataTypeDefinitions, getRowStyle: getRowStyle, pinnedBottomRowData: pinnedBottomRowData, quickFilterText: resultTableSearch }, gridOptions)) })] }), _jsx(RemoveFieldsDialog, { open: showRemove, fieldsSource: canRemoveFields, onClose: () => setShowRemove(false), onConfirm: onRemoveFields }), _jsx(VertexResult, { open: showVertexRes, onClose: () => setShowVertexRes(false), onConfirm: onConfirmVertexRes, json: invalidVertexRes, extraction: (_b = shareData.fileData) === null || _b === void 0 ? void 0 : _b.extraction }), _jsx(AddFieldsDialog, { open: open, fieldsSource: canAddFields, onClose: () => setOpen(false), onConfirm: onAddFields })] }));
};
export default CommissionPreview;
