import { jsx as _jsx } from "react/jsx-runtime";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import CommonFormatter from 'common/Formatter';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Formatter from '@/services/Formatter';
import { FieldTypes } from '@/types';
const CommissionCalcLog = ({ commissionProfile, initialExpanded = false }) => {
    const [expanded, setExpanded] = useState(initialExpanded);
    const logFields = useMemo(() => [
        { field: 'alerts', label: '⚠️', skipEmpty: true, newLine: true },
        {
            field: 'profile_name',
            label: 'Profile',
            skipEmpty: true,
            newLine: true,
        },
        {
            field: 'calcMethod',
            label: 'Method',
            formatter: CommonFormatter.calcMethod,
            skipEmpty: true,
        },
        {
            field: 'commissionAmount',
            label: 'Amount',
            formatter: Formatter.currency,
            skipEmpty: true,
            newLine: true,
        },
        {
            field: 'payerCommissionRate',
            label: 'Payer commission rate',
            formatter: CommonFormatter.percentage,
            skipEmpty: true,
        },
        {
            field: 'commissionRate',
            label: 'Effective rate',
            formatter: CommonFormatter.percentage,
            skipEmpty: true,
        },
        {
            field: 'payoutRate',
            label: 'Payout rate',
            formatter: (val) => CommonFormatter.percentage(val / 100),
            skipEmpty: true,
        },
        {
            field: 'calcBasis',
            label: 'Basis',
            formatter: Formatter.currency,
            collapsed: true,
            skipEmpty: true,
        },
        {
            field: 'criterionStr',
            label: 'Criteria',
            collapsed: true,
            newLine: true,
            skipEmpty: true,
        },
        {
            field: 'payerRates',
            label: 'Payer grid rates',
            collapsed: true,
            newLine: true,
            formatter: (val) => {
                return val.carrier_rate || val.house_rate || val.rate
                    ? `Carrier: ${CommonFormatter.percentage(val.carrier_rate / 100)} • House: ${CommonFormatter.percentage(val.house_rate / 100)} • Total: ${CommonFormatter.percentage(val.rate / 100)}`
                    : null;
            },
            skipEmpty: true,
            nowrap: true,
        },
        {
            field: 'payeeRates',
            label: 'Payee grid rates',
            collapsed: true,
            newLine: true,
            formatter: (val) => {
                return val.carrier_rate || val.house_rate || val.rate
                    ? `Carrier: ${CommonFormatter.percentage(val.carrier_rate / 100)} • House: ${CommonFormatter.percentage(val.house_rate / 100)} • Total: ${CommonFormatter.percentage(val.rate / 100)}`
                    : null;
            },
            skipEmpty: true,
            nowrap: true,
        },
        {
            field: 'agentSplit',
            label: 'Writing agent split',
            formatter: CommonFormatter.percentage,
            skipEmpty: true,
            collapsed: true,
            newLine: true,
        },
        {
            field: 'hierarchySplit',
            label: 'Hierarchy split',
            formatter: CommonFormatter.percentage,
            skipEmpty: true,
            collapsed: true,
        },
        {
            field: 'multiplier',
            label: 'Multiplier',
            formatter: CommonFormatter.percentage,
            skipEmpty: true,
            collapsed: true,
            newLine: true,
        },
        {
            field: 'agentUplines',
            label: 'Agent hierarchy',
            formatter: (val) => Array.isArray(val) && val.length > 0 ? val.join(' / ') : 'n/a',
            skipEmpty: true,
            collapsed: true,
            newLine: true,
        },
        {
            field: 'product_type',
            label: 'Product type',
            collapsed: true,
            skipEmpty: true,
        },
        {
            field: 'referral_rates',
            label: 'Referral rates',
            collapsed: true,
            skipEmpty: true,
        },
        {
            field: 'formula',
            label: 'Formula',
            type: FieldTypes.CUSTOM,
            collapsed: true,
            render: (row) => {
                var _a;
                return (_a = row === null || row === void 0 ? void 0 : row.calResult) === null || _a === void 0 ? void 0 : _a.formula;
            },
            newLine: true,
        },
        {
            field: 'formulaInterpreted',
            label: 'Formula interpreted',
            type: FieldTypes.CUSTOM,
            collapsed: true,
            render: (row) => {
                var _a;
                return (_a = row === null || row === void 0 ? void 0 : row.calResult) === null || _a === void 0 ? void 0 : _a.formulaInterpreted;
            },
            newLine: true,
        },
        {
            field: 'notes',
            label: '📗',
            newLine: true,
            skipEmpty: true,
        },
        {
            field: 'calculatedAt',
            label: 'Calculated at',
            collapsed: true,
            newLine: true,
            skipEmpty: true,
            formatter: (val) => Formatter.dateTime(val),
        },
    ], []);
    const logs = useMemo(() => {
        const logRows = [];
        const currentRow = [];
        logFields
            .filter((log) => expanded || !log.collapsed)
            .forEach((log) => {
            if (log.newLine && currentRow.length) {
                logRows.push(_jsx(Box, { sx: { whiteSpace: 'nowrap' }, children: currentRow.join(', ') }, log.field));
                currentRow.length = 0;
            }
            const value = commissionProfile[log.field];
            if (log.skipEmpty && (value === null || value === undefined))
                return;
            if (log.formatter) {
                currentRow.push(`${log.label}: ${log.formatter(value)}`);
            }
            else if (log.render) {
                const comp = log.render(commissionProfile);
                if (comp) {
                    currentRow.push(`${log.label}: ${log.render(commissionProfile)}`);
                }
            }
            else {
                currentRow.push(`${log.label}: ${value}`);
            }
        });
        logRows.push(_jsx(Box, { sx: logFields[logFields.length - 1].nowrap ? { whiteSpace: 'nowrap' } : {}, children: currentRow.join(', ') }, logFields[logFields.length - 1].field));
        return _jsx(Box, { children: logRows });
    }, [logFields, expanded, commissionProfile]);
    const isReferral = commissionProfile.calcMethod === 'referral';
    return (_jsx(Chip, { sx: expanded
            ? {
                m: 0.1,
                py: 0.5,
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
                maxWidth: 800,
                minWidth: 300,
            }
            : { m: 0.1, py: 0.25, height: 'auto' }, clickable: true, component: Link, to: isReferral
            ? `/agents/list?id=${commissionProfile.contactStrId}`
            : `/commissions/agent-schedule-profiles?id=${commissionProfile === null || commissionProfile === void 0 ? void 0 : commissionProfile.profile_str_id}`, target: "_blank", label: expanded ? (_jsx(Box, { children: _jsx(Box, { sx: { my: 0.2 }, children: logs }) })) : (logs), onDelete: (e) => {
            e.preventDefault();
            setExpanded(!expanded);
        }, deleteIcon: expanded ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}) }));
};
export default CommissionCalcLog;
