import { z } from 'zod';
export const GroupingActions = ['group', 'dedupe'];
export const GroupingDTOSchema = z.object({
    isSync: z.boolean().nullable().optional(),
    policyDataPriorityField: z.string().nullable().optional(),
    taskId: z.string().nullable().optional(),
    worker: z.string().nullable().optional(),
    // At least two statement ids are required
    statementIds: z
        .array(z.number())
        .min(2, { message: 'At least two statement are required' })
        .nullable()
        .optional(),
    masterId: z.number().nullable().optional(),
    // At least two report ids are required
    reportIds: z
        .array(z.number())
        .min(2, { message: 'At least two policies are required' })
        .nullable()
        .optional(),
    isManual: z.boolean().nullable().optional(),
    actions: z.array(z.enum(GroupingActions)).default([...GroupingActions]),
});
