import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { TextField, IconButton, Menu, MenuItem, Autocomplete, Box, Tooltip, Chip, } from '@mui/material';
import CalculateOutlined from '@mui/icons-material/CalculateOutlined';
import PriceCheckOutlined from '@mui/icons-material/PriceCheckOutlined';
import ClearIcon from '@mui/icons-material/Clear';
const FieldAggregationSelector = ({ fields, selectedField, onRemove, onUpdate, }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElFormatter, setAnchorElFormatter] = useState(null);
    const [selectedAggregation, setSelectedAggregation] = useState(selectedField.aggregation_method || '');
    const [selectedFieldValue, setSelectedFieldValue] = useState(selectedField.field || '');
    const [selectedFieldFormatter, setSelectedFieldFormatter] = useState(selectedField.formatter || '');
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleFormatterMenuOpen = (event) => {
        setAnchorElFormatter(event.currentTarget);
    };
    const handleFormatterMenuClose = () => {
        setAnchorElFormatter(null);
    };
    const handleAggregationChange = (method) => {
        setSelectedAggregation(method);
        onUpdate(Object.assign(Object.assign({}, selectedField), { aggregation_method: method }));
        handleMenuClose();
    };
    const handleFormatterChange = (method) => {
        setSelectedFieldFormatter(method);
        onUpdate(Object.assign(Object.assign({}, selectedField), { formatter: method }));
    };
    const handleFieldChange = (event, value) => {
        setSelectedFieldValue(value);
        onUpdate(Object.assign(Object.assign({}, selectedField), { field: value }));
    };
    return (_jsxs("div", { style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '5px',
        }, children: [_jsx(Autocomplete, { "data-name": "field-aggregator-field", options: fields, getOptionLabel: (option) => option.displayName || option.name, value: fields.find((field) => field.name === selectedFieldValue) || null, style: { width: '60%' }, onChange: (event, newValue) => {
                    handleFieldChange(event, newValue ? newValue.name : null);
                }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { variant: "outlined", size: "small" }))) }), _jsxs(Box, { ml: 1, display: 'flex', alignItems: 'center', children: [selectedAggregation ? (_jsx(Chip, { sx: { marginRight: 1 }, "data-name": "field-aggregator-aggregation", onClick: handleMenuOpen, label: selectedAggregation, avatar: _jsx(CalculateOutlined, {}) })) : (_jsx(Tooltip, { title: "Aggregation method", children: _jsx(IconButton, { onClick: handleMenuOpen, children: _jsx(CalculateOutlined, {}) }) })), selectedFieldFormatter ? (_jsx(Chip, { "data-name": "field-aggregator-formatter", label: selectedFieldFormatter, avatar: _jsx(PriceCheckOutlined, {}), onClick: handleFormatterMenuOpen })) : (_jsx(Tooltip, { title: "Result formatter", children: _jsx(IconButton, { onClick: handleFormatterMenuOpen, children: _jsx(PriceCheckOutlined, {}) }) })), _jsx(IconButton, { onClick: onRemove, children: _jsx(ClearIcon, {}) })] }), _jsxs(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, children: [_jsx(MenuItem, { onClick: () => handleAggregationChange('Sum'), children: "Sum" }), selectedFieldValue && selectedFieldValue.type === 'obj' && (_jsx(MenuItem, { onClick: () => handleAggregationChange('Aggregate'), children: "Aggregate" })), _jsx(MenuItem, { onClick: () => handleAggregationChange('Average'), children: "Average" }), _jsx(MenuItem, { onClick: () => handleAggregationChange('Count'), children: "Count" })] }), _jsxs(Menu, { anchorEl: anchorElFormatter, open: Boolean(anchorElFormatter), onClose: handleFormatterMenuClose, children: [_jsx(MenuItem, { onClick: () => handleFormatterChange('currency'), children: "Currency" }), _jsx(MenuItem, { onClick: () => handleFormatterChange('percentage'), children: "Percentage" }), _jsx(MenuItem, { onClick: () => handleFormatterChange('number'), children: "Number" })] })] }));
};
export default FieldAggregationSelector;
