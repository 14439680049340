import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import prettyMilliseconds from 'pretty-ms';
import { AccountIds, CALC_METHODS } from './constants';
import { CompGridRateFields } from './globalTypes';
import { isNill } from './helpers';
import BigNumber from 'bignumber.js';
import currency from 'currency.js';
dayjs.extend(utc);
class Formatter {
}
Formatter.calcMethod = (s) => { var _a; return (_a = CALC_METHODS[s]) !== null && _a !== void 0 ? _a : s; };
Formatter.asPercentage = (s) => {
    if (typeof s === 'number') {
        if (Number.isFinite(s)) {
            return `${(s * 100).toFixed(2)}%`;
        }
        else {
            return 'n/a';
        }
    }
    return s;
};
Formatter.currency = (s) => {
    if (s === undefined || s === '')
        return '';
    let s1 = s;
    if (typeof s1 === 'string') {
        s1 = s.trim();
    }
    if (typeof s1 === 'string') {
        s1 = s.replaceAll(',', '');
        s1 = s1.replaceAll('$', '');
    }
    if (typeof s1 === 'string' && s1.split('\n').length > 1) {
        [s1] = s.split('\n');
    }
    if (typeof s1 === 'string' && s1.split(' ').length > 1) {
        [s1] = s1.split(' ');
    }
    if (typeof s1 === 'string' && s1.endsWith('-')) {
        s1 = `-${s1.slice(0, -1)}`;
    }
    if (typeof s1 === 'string' && s1.startsWith('(') && s1.endsWith(')')) {
        s1 = `-${s1.slice(1, -1)}`;
    }
    s1 = parseFloat(s1);
    if (Number.isNaN(s1)) {
        s1 = '';
    }
    else {
        // Using currency for now, but should be replaced with BigNumber
        s1 = currency(s1).format();
        // const bigNumber = new BigNumber(s1);
        // s1 = bigNumber.toFormat(2, BigNumber.ROUND_HALF_UP);
        // Example:
        // -0.375: Original value
        // -0.38: Rounded using BigNumber with ROUND_HALF_UP
        // -$0.37: Rounded and formatted using currency.js
    }
    return s1;
};
Formatter.percentage = (val) => {
    if (typeof val === 'string' || isNill(val)) {
        return val;
    }
    const percentageValue = new BigNumber(val)
        .multipliedBy(100)
        .decimalPlaces(3);
    return percentageValue.isLessThan(100)
        ? `${percentageValue}%`
        : `${percentageValue.integerValue(BigNumber.ROUND_HALF_UP).toString()}%`;
};
Formatter.numberRange = (start, end) => {
    if (![null, undefined, ''].includes(start) && start === end) {
        return start;
    }
    else if (start && end) {
        return `${start}-${end}`;
    }
    else if (start) {
        return `${start}+`;
    }
    else if (end) {
        return `< ${end}`;
    }
    else {
        return 'any';
    }
};
Formatter.duration = (val, opts = { truncate: undefined }) => {
    let res = prettyMilliseconds(+val, { secondsDecimalDigits: 0 });
    if ((opts === null || opts === void 0 ? void 0 : opts.truncate) === 'seconds') {
        res = res.replace(/ \d+s/, '');
    }
    return res;
};
Formatter.dateRange = (start, end, useUTC = true) => {
    const _dayjs = useUTC ? dayjs.utc : dayjs;
    const _startStr = _dayjs(start).format('MM/DD/YYYY');
    const _endStr = _dayjs(end).format('MM/DD/YYYY');
    const startStr = _startStr === 'Invalid Date' ? null : _startStr;
    const endStr = _endStr === 'Invalid Date' ? null : _endStr;
    if (!startStr && !endStr) {
        return 'Full date range';
    }
    else if (startStr && startStr === endStr) {
        return startStr;
    }
    else if (startStr && endStr) {
        return `${startStr}-${endStr}`;
    }
    else if (startStr) {
        return `${startStr} or later`;
    }
    else if (endStr) {
        return `${endStr} or earlier`;
    }
    else {
        return '';
    }
};
Formatter.compGridCriterion = (val) => {
    var _a, _b;
    return val
        ? `${(_a = val.comp_grid_product) === null || _a === void 0 ? void 0 : _a.type} • ${(_b = val.comp_grid_product) === null || _b === void 0 ? void 0 : _b.name} • Years (${Formatter.numberRange(val.policy_year_start, val.policy_year_end)}) • Ages (${Formatter.numberRange(val.issue_age_start, val.issue_age_end)})${val.compensation_type ? ` • ${val.compensation_type}` : ''}${val.transaction_type ? ` • ${val.transaction_type}` : ''}`
        : '';
};
Formatter.date = (val, useUTC = true, format = 'MM/DD/YYYY') => {
    const _dayjs = useUTC ? dayjs.utc : dayjs;
    return _dayjs(val).format(format);
};
Formatter.contact = (contact, opts = {
    incl_email: false,
    last_first: false,
}) => {
    var _a;
    const isTransGlobal = (opts === null || opts === void 0 ? void 0 : opts.account_id) === AccountIds.TRANSGLOBAL;
    const _lastFirst = (_a = (isTransGlobal || (opts === null || opts === void 0 ? void 0 : opts.last_first))) !== null && _a !== void 0 ? _a : false;
    if (!contact) {
        return '';
    }
    const arr = [];
    const nameArr = [];
    if (contact.first_name)
        nameArr.push(contact.first_name.trim());
    if (contact.last_name)
        nameArr.push(contact.last_name.trim());
    if (_lastFirst) {
        nameArr.reverse();
        arr.push(nameArr.join(', '));
    }
    else {
        arr.push(nameArr.join(' '));
    }
    if ((opts === null || opts === void 0 ? void 0 : opts.incl_email) && contact.email)
        arr.push(`(${contact.email.trim()})`);
    const str = arr.join(' ');
    return str;
};
Formatter.compGridRatesFields = (val) => {
    if (val === CompGridRateFields.CARRIER_RATE) {
        return 'Carrier Rate';
    }
    else if (val === CompGridRateFields.HOUSE_RATE) {
        return 'House Rate';
    }
    else if (val === CompGridRateFields.TOTAL_RATE) {
        return 'Total Rate';
    }
    else {
        return 'All';
    }
};
export default Formatter;
