import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { FiltersOperators } from 'common/globalTypes';
import { DataTypes } from '@/types';
import FieldMatcherComponent from '@/components/molecules/FieldMatcherComponent';
import { EnhancedSelect } from './EnhancedSelect';
const isNumeric = (type) => [
    DataTypes.NUMBER,
    DataTypes.CURRENCY,
    DataTypes.INTEGER,
    DataTypes.PERCENTAGE,
    DataTypes.DECIMAL,
].includes(type);
const isString = (type) => [DataTypes.TEXT].includes(type);
const isDate = (type) => {
    return [DataTypes.DATE].includes(type);
};
const isJson = (type) => {
    return [DataTypes.JSON].includes(type);
};
const isArray = (type) => {
    return [DataTypes.ARRAY].includes(type);
};
const isNotWritingAgent = (label) => {
    const labels = ['Agents'];
    return labels.includes(label);
};
const isWritingAgent = (label) => {
    const labels = ['Agents'];
    return labels.includes(label);
};
export const fieldMatcherOptions = [
    {
        id: FiltersOperators.EQNUM,
        label: 'Equals',
        shortLabel: '=',
        validate: [isNumeric],
    },
    {
        id: FiltersOperators.NEQNUM,
        label: 'Not equals',
        shortLabel: '!=',
        validate: [isNumeric],
    },
    {
        id: FiltersOperators.EQ,
        label: 'Equals',
        shortLabel: '=',
        validate: [isString, isArray],
    },
    {
        id: FiltersOperators.NEQ,
        label: 'Not equals',
        shortLabel: '!=',
        validate: [isString, isArray],
    },
    {
        id: FiltersOperators.GT,
        label: 'Greater than',
        shortLabel: '>',
        validate: [isNumeric],
    },
    {
        id: FiltersOperators.LT,
        label: 'Less than',
        shortLabel: '<',
        validate: [isNumeric],
    },
    {
        id: FiltersOperators.GTE,
        label: 'Greater than or equals',
        shortLabel: '>=',
        validate: [isNumeric],
    },
    {
        id: FiltersOperators.LTE,
        label: 'Less than or equals',
        shortLabel: '<=',
        validate: [isNumeric],
    },
    {
        id: FiltersOperators.CONTAINS,
        label: 'Contains',
        validate: [isString, isArray],
    },
    {
        id: FiltersOperators.NCONTAINS,
        label: 'Not contains',
        validate: [isString, isArray],
    },
    {
        id: FiltersOperators.CONTAINEDIN,
        label: 'Contained in',
        validate: [isJson, isString],
    },
    {
        id: FiltersOperators.NCONTAINEDIN,
        label: 'Not contained in',
        validate: [isJson, isString],
    },
    {
        id: FiltersOperators.STARTSWITH,
        label: 'Starts with',
        validate: [isString],
    },
    { id: FiltersOperators.ENDSWITH, label: 'Ends with', validate: [isString] },
    { id: FiltersOperators.BEFORE, label: 'Before', validate: [isDate] },
    {
        id: FiltersOperators.BEFORE_EQUALS,
        label: 'Before or equals',
        validate: [isDate],
    },
    { id: FiltersOperators.AFTER, label: 'After', validate: [isDate] },
    {
        id: FiltersOperators.AFTER_EQUALS,
        label: 'After or equals',
        validate: [isDate],
    },
    { id: FiltersOperators.IS_EMPTY, label: 'Is empty' },
    {
        id: FiltersOperators.NOT_WRITING_AGENT,
        label: 'Not writing agent',
        validate: [isNotWritingAgent],
    },
    {
        id: FiltersOperators.IS_WRITING_AGENT,
        label: 'Is writing agent',
        validate: [isWritingAgent],
    },
    {
        id: FiltersOperators.WITHIN_ONE_YEAR,
        label: 'Within one year',
        validate: [isDate],
    },
    {
        id: FiltersOperators.AT_LEAST_ONE_YEAR,
        label: 'At least one year',
        validate: [isDate],
    },
    {
        id: FiltersOperators.CUSTOM,
        label: 'Unset date range',
        validate: [isDate],
    },
    {
        id: FiltersOperators.IS_NOT_EMPTY,
        label: 'Is not empty',
        validate: [isArray],
    },
];
const FieldMatcher = ({ fields, value: fieldsMatchers = [], setValue, addLabel = 'Add', hideUsePolicyData = false, sx = {}, }) => {
    const getSelectedField = (fieldId) => {
        return fields.find((f) => f.id === fieldId);
    };
    const addtionalOptions = [
        { name: 'Case sensitive ', id: 'caseSensitive', validate: [isString] },
        { name: 'Skip empty', id: 'skipEmpty' },
    ];
    if (!hideUsePolicyData) {
        addtionalOptions.push({ name: 'Use policy data', id: 'usePolicyData' });
    }
    return (_jsxs(Box, { sx: Object.assign({}, sx), children: [fieldsMatchers === null || fieldsMatchers === void 0 ? void 0 : fieldsMatchers.map((fieldMatcher, i) => fieldMatcher.type !== 'Action' && (_jsxs(Box, { sx: {
                    mt: 1,
                    display: 'flex',
                    flexWrap: 'no-wrap',
                    width: '100%',
                    alignItems: 'center',
                }, children: [_jsx(EnhancedSelect, { enableSearch: true, label: "Field", options: fields === null || fields === void 0 ? void 0 : fields.sort((a, b) => (b.label > a.label ? -1 : 1)), labelKey: "label", value: fields.find((item) => item.id === fieldMatcher.field), onChange: (item) => {
                            const newFieldMatchers = [...fieldsMatchers];
                            newFieldMatchers[i] = { field: item.id };
                            setValue(newFieldMatchers);
                        }, sx: { marginRight: 1 } }), (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.field) ? (_jsxs(_Fragment, { children: [_jsx(EnhancedSelect, { enableSearch: true, label: "Operator", options: fieldMatcherOptions.filter((op) => {
                                    const selectedField = getSelectedField(fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.field);
                                    if (op.validate &&
                                        selectedField &&
                                        !op.validate.some((validateFn) => {
                                            var _a;
                                            return validateFn((_a = selectedField === null || selectedField === void 0 ? void 0 : selectedField.fieldMatcherType) !== null && _a !== void 0 ? _a : selectedField === null || selectedField === void 0 ? void 0 : selectedField.type) || validateFn(selectedField === null || selectedField === void 0 ? void 0 : selectedField.label);
                                        })) {
                                        return false;
                                    }
                                    return true;
                                }), labelKey: "label", value: fieldMatcherOptions.find((item) => item.id === fieldMatcher.op), onChange: (item) => {
                                    const newFieldMatchers = [...fieldsMatchers];
                                    newFieldMatchers[i].op = item.id;
                                    setValue(newFieldMatchers);
                                }, sx: { marginRight: 1 } }), (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) ? (_jsxs(_Fragment, { children: [_jsx(FieldMatcherComponent, { fields: fields, fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue }, i), (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.value) ? (_jsx(EnhancedSelect, { multiple: true, label: "Options", options: addtionalOptions.filter((op) => {
                                            const selectedField = getSelectedField(fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.field);
                                            if (op.validate &&
                                                selectedField &&
                                                !op.validate.some((validateFn) => {
                                                    var _a;
                                                    return validateFn((_a = selectedField === null || selectedField === void 0 ? void 0 : selectedField.fieldMatcherType) !== null && _a !== void 0 ? _a : selectedField === null || selectedField === void 0 ? void 0 : selectedField.type);
                                                })) {
                                                return false;
                                            }
                                            return true;
                                        }), value: addtionalOptions.filter((item) => {
                                            return fieldsMatchers[i][item.id];
                                        }), onChange: (v) => {
                                            const newFieldMatchers = [...fieldsMatchers];
                                            addtionalOptions.forEach((item) => {
                                                newFieldMatchers[i][item.id] = false;
                                            });
                                            v.forEach((item) => {
                                                newFieldMatchers[i][item.id] = true;
                                            });
                                            setValue(newFieldMatchers);
                                        } })) : null] })) : null] })) : null, _jsx(Box, { children: _jsx(IconButton, { onClick: () => {
                                const newFieldMatchers = [...fieldsMatchers];
                                newFieldMatchers.splice(i, 1);
                                setValue(newFieldMatchers);
                            }, children: _jsx(RemoveCircleOutline, {}) }) })] }, i))), _jsx(Button, { onClick: () => setValue([...fieldsMatchers, {}]), sx: { mt: 0.5 }, children: addLabel })] }));
};
export default FieldMatcher;
