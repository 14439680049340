var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Reports_mode, _Reports_strings;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Launch, VerticalSplit } from '@mui/icons-material';
import { Box, Chip, IconButton, Skeleton, Typography } from '@mui/material';
import { CustomerType } from 'common/customer/customer.constants';
import copy from 'copy-to-clipboard';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import AgentCommissionsEdit from '@/components/CommissionsDataView/AgentCommissionsEdit';
import DataPreviewTable from '@/components/molecules/DataPreviewTable';
import ExpandableData from '@/components/molecules/ExpandableData';
import AgentCommissionSplitConfig from '@/components/PolicyDataView/AgentCommissionSplitConfig';
import { ROUTES } from '@/constants/routes';
import { ComparisonContext } from '@/contexts/ReconciliationConfirmProvider';
import states from '@/data/states.json';
import DataTransformation from '@/services/DataTransformation';
import Formatter from '@/services/Formatter';
import UILabels from '@/services/UILabels';
import { FieldTypes, Roles } from '@/types';
import { CustomerField } from './Reports/CustomerField';
import CommissionCalcLog from '@/components/molecules/CommissionCalcLog';
import { CHAR_WIDTH } from '@/components/molecules/EnhancedTable/constants';
const Normalizer = DataTransformation;
class Reports {
    constructor(mode, options) {
        var _a;
        _Reports_mode.set(this, 'default');
        _Reports_strings.set(this, new UILabels(__classPrivateFieldGet(this, _Reports_mode, "f")));
        this.outstandingFieldsInMobileView = [];
        this.label = 'Transactions';
        this.table = 'report_data';
        this.copyable = true;
        this.options = {};
        this.useNewTable = false;
        this.getDataInfo = (data, topN = 5) => {
            const rowData = [];
            const ranges = [];
            let curRange = { start: 0 };
            data.forEach((row, i) => {
                let matches = 0;
                let filled = 0;
                row.forEach((cell) => {
                    if (cell || cell === 0) {
                        filled += 1;
                    }
                    if (this.fieldsCollapsed.includes(cell.toString().toLowerCase())) {
                        matches += 1;
                    }
                });
                if (filled === 0 && curRange !== null) {
                    curRange.end = i - 1;
                    ranges.push(curRange);
                    curRange = null;
                }
                else if (filled > 0 && curRange === null) {
                    curRange = { start: i };
                }
                rowData.push({ index: i, matches, filled, length: row.length });
                // AllMatches[matches] = [...(allMatches?.[matches] || []), i];
            });
            if (curRange !== null) {
                ranges.push(Object.assign(Object.assign({}, curRange), { end: data.length - 1 }));
            }
            const rangeData = rowData
                .sort((a, b) => b.matches - a.matches)
                .filter((row) => row.matches > 0)
                .splice(0, topN)
                .map((row) => ({
                index: row.index,
                count: row.matches,
                fields: data[row.index],
                data: data.filter((dataRow, i) => i > ranges.filter((a) => a.start === row.index)[0].start &&
                    i <= ranges.filter((a) => a.start === row.index)[0].end),
            }));
            return { rowData, ranges, rangeData };
        };
        this.options = options;
        __classPrivateFieldSet(this, _Reports_mode, mode, "f");
        __classPrivateFieldSet(this, _Reports_strings, new UILabels(mode), "f");
        this.deDupeFields = (_a = options === null || options === void 0 ? void 0 : options.deDupeFields) !== null && _a !== void 0 ? _a : this.deDupeFields;
        this.label = __classPrivateFieldGet(this, _Reports_strings, "f").getLabel('transactions', 'title');
        this.labelSimple = __classPrivateFieldGet(this, _Reports_strings, "f").getLabel('transactions', 'titleSimple');
        this.copyable = true;
        this.filters = {
            agent_name: {
                label: 'Agent name',
                useEnhancedSelect: true,
            },
            contacts: {
                label: 'Agents',
                useEnhancedSelect: true,
            },
            document_id: {
                label: 'Document',
                useEnhancedSelect: true,
            },
            policy_status: {
                label: 'Status',
                useEnhancedSelect: true,
            },
            product_name: {
                label: 'Product name',
                useEnhancedSelect: true,
            },
            product_type: {
                label: 'Product type',
                useEnhancedSelect: true,
            },
            writing_carrier_name: {
                label: 'Master company',
                useEnhancedSelect: true,
            },
            account_type: {
                label: 'Account type',
                useEnhancedSelect: true,
            },
            tags: {
                label: 'Tags',
                useEnhancedSelect: true,
            },
        };
        this.stickyColumns = ['policy_id'];
        this.fields = {
            agent_name: {
                label: __classPrivateFieldGet(this, _Reports_strings, "f").getLabel('transactions', 'agent'),
                bulkEdit: true,
                matches: [
                    'assigned agent',
                    'agent',
                    'agt',
                    'writing agent',
                    'writing agt',
                    'agent name',
                    'agt name',
                    'assigned_agent',
                    'producer name',
                    'assigned producer',
                ],
                enabled: true,
                global: true,
            },
            customer_name: {
                bulkEdit: true,
                label: 'Customer name',
                matches: [
                    'customer name',
                    'customer',
                    'insuree',
                    'client',
                    'client name',
                    'insured name',
                    'policy holder',
                    'policy holder name',
                    'subscriber',
                    'name of insured',
                    'customername',
                    'account name',
                ],
                style: {
                    pl: 2,
                },
                getWidth: ({ estimatedWidth }) => {
                    // TODO: find better solution
                    const headerWidth = 'Customer name'.length * CHAR_WIDTH + 12 + 24 + 8;
                    return Math.max(estimatedWidth, headerWidth);
                },
                required: false,
                reconciler: true,
                enabled: true,
                formatter: (v, row) => {
                    var _a;
                    if (row === null || row === void 0 ? void 0 : row.customer) {
                        const name = ((_a = row === null || row === void 0 ? void 0 : row.customer) === null || _a === void 0 ? void 0 : _a.type) === CustomerType.individual
                            ? `${row.customer.first_name} ${row.customer.last_name}`
                            : row.customer.company_name;
                        return (_jsx(Chip, { label: name, clickable: true, component: "a", href: `${ROUTES.customers.url}?q=${row.customer.first_name}`, target: "_blank", sx: { m: 0.1 } }));
                    }
                    return v;
                },
                type: FieldTypes.CUSTOM,
                render: (_, newData, setNewData) => {
                    const onChange = (v) => {
                        if (v.id === -1) {
                            setNewData(Object.assign(Object.assign({}, newData), { customer_name: v.first_name, customer_id: null, customer: null }));
                        }
                        else {
                            setNewData(Object.assign(Object.assign({}, newData), { customer_name: '', customer_id: v.id, customer: v }));
                        }
                    };
                    return (_jsx(CustomerField, { value: newData.customer || newData.customer_name, onChange: onChange }));
                },
            },
            dba: {
                label: 'DBA',
                matches: ['dba', 'aka'],
                required: false,
                reconciler: true,
                enabled: true,
                bulkEdit: true,
            },
            // CustomerFirstName: {
            //   label: 'Insured first name',
            //   matches: ['customer first name', 'first name'],
            //   required: 'name',
            //   enabled: true,
            // },
            // customerLastName: {
            //   label: 'Insured last name',
            //   matches: ['customer last name', 'last name'],
            //   required: 'name',
            //   enabled: true,
            // },
            policy_id: {
                sticky: 'left',
                label: __classPrivateFieldGet(this, _Reports_strings, "f").getLabel('transactions', 'salesId'),
                bulkEdit: true,
                matches: [
                    'policy id',
                    'policy no',
                    'policy number',
                    'policy #',
                    'policy_number',
                    'policyid',
                ],
                formatter: (s) => {
                    var _a, _b;
                    return Formatter.policyNumber((_a = s === null || s === void 0 ? void 0 : s.toString()) !== null && _a !== void 0 ? _a : '', {
                        account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                    });
                },
                normalizer: (s) => { var _a; return (_a = s === null || s === void 0 ? void 0 : s.toString()) !== null && _a !== void 0 ? _a : ''; },
                enabled: true,
                reconciler: true,
                copyable: true,
                getWidth: ({ estimatedWidth }) => estimatedWidth + 30, // 40px for copy icon
            },
            internal_id: {
                bulkEdit: true,
                label: 'Internal Id',
                matches: ['internal id', 'internalid'],
                enabled: true,
            },
            effective_date: {
                // Date
                bulkEdit: true,
                label: __classPrivateFieldGet(this, _Reports_strings, "f").getLabel('transactions', 'startDate'),
                matches: [
                    'policy effective date',
                    'effective date',
                    'effective',
                    'eff date',
                    'in force date',
                    'effective_date',
                    'effective_date2',
                    'effectivedate',
                ],
                enabled: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
                required: false,
                type: 'date',
            },
            signed_date: {
                label: 'Signed date',
                type: 'date',
                enabled: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
                required: false,
                bulkEdit: true,
            },
            policy_date: {
                label: 'Policy date',
                type: 'date',
                enabled: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
                required: false,
                bulkEdit: true,
            },
            accounting_transaction_details: {
                label: 'Expected receivables',
                required: false,
                enabled: true,
                type: FieldTypes.CUSTOM,
                bulkEdit: true,
                tableFormatter: (vals) => {
                    return (_jsx(Box, { children: vals === null || vals === void 0 ? void 0 : vals.map((val) => {
                            var _a;
                            return (_jsx(Chip, { label: val.party === 'agent'
                                    ? `Agent (${Formatter.contact(val.agent)}): ${val.rate}%`
                                    : val.party === 'agency'
                                        ? `Override (${(_a = val === null || val === void 0 ? void 0 : val.logs) === null || _a === void 0 ? void 0 : _a.contact}): ${val.rate}%`
                                        : val.party === 'policy'
                                            ? `Agency: ${val.rate}%`
                                            : `${val.party} - ${val.rate}`, clickable: true, component: "a", href: `/accounting/transaction-details?id=${val.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.str_id));
                        }) }, "accounting_transaction_details"));
                },
                render: (field, row) => {
                    return row && Array.isArray(row.accounting_transaction_details) ? (_jsxs(Box, { children: [_jsx(Typography, { children: "Expected receivables" }), row.accounting_transaction_details.map((val) => (_jsx(Chip, { label: val.str_id, clickable: true, component: "a", href: `/accounting/transaction-details?id=${val.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.str_id)))] }, "accounting_transaction_details")) : null;
                },
            },
            writing_carrier_name: {
                width: 350,
                label: __classPrivateFieldGet(this, _Reports_strings, "f").getLabel('transactions', 'payingEntity'),
                bulkEdit: true,
                matches: [
                    'mga/broker',
                    'mga',
                    'broker',
                    'carrier name',
                    'carrier',
                    'carriername',
                ],
                enabled: true,
                required: false,
                reconciler: true,
                global: true,
                type: 'dynamic-select',
                table: 'companies',
                field: 'company_name',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val &&
                        Array.isArray(collectionVals) &&
                        collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((company) => company.company_name === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? (_jsx(Chip, { label: `${datum.company_name}`, clickable: true, component: "a", href: `/companies?q=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
                optionFormatter: (option) => option.company_name,
                optionValuer: (option) => option.company_name,
            },
            premium_amount: {
                label: __classPrivateFieldGet(this, _Reports_strings, "f").getLabel('transactions', 'annualizedRevenue'),
                description: 'Annualized premium amount as recorded in production reports',
                matches: [
                    'policy premium',
                    'premium amount',
                    'premium amt',
                    'premium paid',
                    'premium',
                    'premium - annualized',
                    'annualized_premium',
                    'annualized_premium2',
                    'premiumamount',
                ],
                required: false,
                enabled: true,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: 'currency',
            },
            product_type: {
                bulkEdit: true,
                label: 'Product type',
                matches: [
                    'product type',
                    'product line',
                    'line_of_business',
                    'producttype',
                ],
                enabled: true,
            },
            product_sub_type: {
                label: 'Product sub type',
                matches: [],
                enabled: true,
                bulkEdit: true,
            },
            product_name: {
                width: 250,
                label: 'Product name',
                bulkEdit: true,
                matches: ['product name', 'product'],
                reconciler: true,
                enabled: __classPrivateFieldGet(this, _Reports_mode, "f") === 'insurance',
                type: 'dynamic-select',
                table: 'companies/products',
                queryParamName: 'company_name',
                queryParamValue: 'writing_carrier_name',
                field: 'product_name',
                tip: 'Master company is required to set product name',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val &&
                        Array.isArray(collectionVals) &&
                        collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((company) => company.product_name === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? (_jsx(Chip, { label: `${datum.product_name}`, clickable: true, component: "a", href: `/companies/products?q=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
                optionFormatter: (option) => option.product_name,
                optionValuer: (option) => option.product_name ? option.product_name : '',
            },
            product_option_name: {
                label: 'Product option name',
                matches: ['option', 'product option'],
                reconciler: true,
                enabled: __classPrivateFieldGet(this, _Reports_mode, "f") === 'insurance',
                bulkEdit: true,
            },
            cancellation_date: {
                label: 'Cancellation date',
                enabled: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
                type: 'date',
                bulkEdit: true,
            },
            reinstatement_date: {
                label: 'Reinstatement date',
                enabled: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
                type: 'date',
                bulkEdit: true,
            },
            transaction_type: {
                // New, renewal, cancellation, rewrite, fees
                label: 'Transaction type',
                matches: [
                    'policy business type',
                    'transaction type',
                    'commission type',
                    'policy type',
                    'transactiontype',
                ],
                required: false,
                enabled: __classPrivateFieldGet(this, _Reports_mode, "f") === 'insurance',
                bulkEdit: true,
            },
            commissions_expected: {
                label: 'Commissions due',
                description: 'Commission amount due as recorded in production reports',
                matches: [
                    'agency commission total',
                    'commissions expected',
                    'commission expected',
                    'commissionsexpected',
                ],
                required: false,
                enabled: __classPrivateFieldGet(this, _Reports_mode, "f") === 'insurance',
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: 'currency',
                bulkEdit: true,
            },
            policy_status: {
                label: 'Status',
                description: 'Policy status',
                matches: ['status', 'active', 'policystatus'],
                required: false,
                enabled: true,
                bulkEdit: true,
            },
            account_type: {
                label: 'Account type',
                required: false,
                enabled: true,
                matches: ['account type', 'accounttype'],
                bulkEdit: true,
            },
            geo_state: {
                label: 'State',
                type: 'select',
                options: states,
                enabled: true,
                bulkEdit: true,
            },
            split_percentage: {
                label: 'Split percentage',
                enabled: true,
                matches: ['split percentage'],
                formatter: Normalizer.formatPercentage,
                normalizer: Normalizer.normalizePercentage,
                type: FieldTypes.PERCENTAGE,
                bulkEdit: true,
            },
            group_name: {
                label: 'Group Name',
                matches: ['group name'],
                required: false,
                enabled: true,
                bulkEdit: true,
            },
            payment_mode: {
                label: 'Payment Mode',
                matches: ['payment mode'],
                required: false,
                enabled: true,
                bulkEdit: true,
            },
            policy_term_months: {
                label: 'Policy term (months)',
                required: false,
                enabled: true,
                normalizer: Normalizer.normalizeInt,
                matches: ['policy term', 'policyterm'],
                type: 'integer',
                bulkEdit: true,
            },
            commissionable_premium_amount: {
                label: 'Target premium',
                matches: ['target premium', 'commissionable premium'],
                enabled: true,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: 'currency',
                bulkEdit: true,
            },
            notes: {
                label: 'Notes',
                matches: ['notes', 'note', 'remark', 'notation', 'memo'],
                required: false,
                enabled: true,
                bulkEdit: true,
            },
            group_id: {
                label: 'Group Id',
                matches: [
                    'group id',
                    'group number',
                    'group no',
                    'grp #',
                    'grp number',
                    'groupid',
                ],
                enabled: __classPrivateFieldGet(this, _Reports_mode, "f") === 'insurance',
                required: false,
                bulkEdit: true,
            },
            contacts: {
                getWidth: ({ estimatedWidth, allRows, dynamicSelectData }) => {
                    const mapData = dynamicSelectData === null || dynamicSelectData === void 0 ? void 0 : dynamicSelectData.reduce((acc, curr) => {
                        acc[curr.str_id] = curr;
                        return acc;
                    }, {});
                    if (!mapData)
                        return 0;
                    allRows.forEach((row) => {
                        row.contacts.forEach((id) => {
                            var _a;
                            const contact = mapData[id];
                            const label = Formatter.contact(contact, {
                                account_id: (_a = this.options) === null || _a === void 0 ? void 0 : _a.account_id,
                            });
                            const width = label.length * CHAR_WIDTH;
                            if (width > estimatedWidth) {
                                estimatedWidth = width;
                            }
                        });
                    });
                    return estimatedWidth;
                },
                label: 'Agents',
                bulkEdit: true,
                required: false,
                enabled: true,
                type: 'dynamic-select',
                multiple: true,
                table: 'contacts',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((datum) => datum.str_id === val);
                        return datum ? (_jsx(Chip, { label: Formatter.contact(datum, {
                                account_id: (_a = this.options) === null || _a === void 0 ? void 0 : _a.account_id,
                            }), clickable: true, component: "a", href: `/agents/list?id=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val; // Not formatting when collectionVals is not available
                },
                optionFormatter: (option) => {
                    var _a;
                    return Formatter.contact(option, {
                        account_id: (_a = this.options) === null || _a === void 0 ? void 0 : _a.account_id,
                        incl_email: true,
                    });
                },
                optionValuer: (option) => option.str_id,
                // PreSetter: (row) => {
                //   row.contacts_split = Object.fromEntries(
                //     Object.entries(row.contacts_split ?? {}).filter(([k, v]) =>
                //       (row.contacts ?? []).includes(k)
                //     )
                //   );
                //   return row;
                // },
            },
            contacts_split: {
                label: 'Agent policy split',
                enabled: true,
                type: 'custom',
                table: 'contacts',
                tableFormatter: (val, row, dynamicSelects) => val && (_jsx(Box, { children: Object.keys(val).length > 0 && dynamicSelects ? (_jsxs(Box, { children: [Object.entries(val !== null && val !== void 0 ? val : {}).map(([k, v]) => {
                                var _a;
                                return (_jsxs(Box, { children: [Formatter.contact((_a = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {}, { account_id: this.options.account_id }), ": ", v, "%"] }, k));
                            }), Object.values(val !== null && val !== void 0 ? val : {}).reduce((a, b) => +a + +b, 0) !==
                                100 && (_jsx(Typography, { variant: "caption", sx: { color: 'red' }, children: "Total should be 100" }))] })) : Object.keys(val).length > 0 && !dynamicSelects ? (_jsx(Skeleton, { variant: "rectangular" })) : null })),
                render: (field, row, setter, dynamicSelects) => (_jsx(AgentCommissionSplitConfig, { title: "Agent policy split", data: row, contacts: row.contacts, split: row.contacts_split, setter: setter, setterKey: 'contacts_split', dynamicSelects: dynamicSelects }, 'agent-policy-split-config')),
                access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
                bulkEdit: true,
            },
            contacts_commission_split: {
                label: 'Agent commission split',
                enabled: true,
                type: 'custom',
                table: 'contacts',
                tableFormatter: (val, row, dynamicSelects) => val && (_jsx(Box, { children: Object.keys(val).length > 0 && dynamicSelects ? (_jsxs(Box, { children: [Object.entries(val !== null && val !== void 0 ? val : {}).map(([k, v]) => {
                                var _a;
                                return (_jsxs(Box, { children: [Formatter.contact((_a = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {}, { account_id: this.options.account_id }), ": ", v, "%"] }, k));
                            }), Object.values(val !== null && val !== void 0 ? val : {}).reduce((a, b) => +a + +b, 0) !==
                                100 && (_jsx(Typography, { variant: "caption", sx: { color: 'red' }, children: "Total should be 100" }))] })) : Object.keys(val).length > 0 && !dynamicSelects ? (_jsx(Skeleton, { variant: "rectangular" })) : null })),
                render: (field, row, setter, dynamicSelects) => (_jsx(AgentCommissionSplitConfig, { title: "Agent commission split", data: row, contacts: row.contacts, split: row.contacts_commission_split, setter: setter, setterKey: 'contacts_commission_split', dynamicSelects: dynamicSelects }, 'agent-commission-split-config')),
                bulkEdit: true,
                access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
            },
            issue_age: {
                label: 'Issue age',
                required: false,
                enabled: true,
                normalizer: Normalizer.normalizeInt,
                matches: ['issue age'],
                type: 'integer',
                bulkEdit: true,
            },
            customer_paid_premium_amount: {
                label: 'Customer Paid Premium Amount',
                required: false,
                enabled: true,
                matches: ['Basis', 'customer paid premium amount'],
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                bulkEdit: true,
            },
            aggregation_id: {
                label: 'Aggregation Id',
                enabled: true,
                bulkEdit: true,
            },
            aggregation_primary: {
                label: 'Head of household',
                type: 'boolean',
                enabled: true,
                normalizer: Normalizer.normalizeBoolean,
                formatter: Formatter.boolean,
                bulkEdit: true,
            },
            statement_data: {
                width: 250,
                label: 'Commissions',
                required: false,
                enabled: true,
                type: 'custom',
                table: 'contacts',
                readOnly: true,
                tableFormatter: (vals, row) => {
                    const ctx = useContext(ComparisonContext);
                    return (_jsx(ExpandableData, { header: { label: 'Commissions' }, formatter: (val) => (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx("span", { style: { whiteSpace: 'nowrap' }, children: val.str_id }), _jsx(IconButton, { onClick: () => {
                                        ctx.setData({
                                            statements: vals,
                                            policy: row,
                                            defaultOpenStatement: val.str_id,
                                        });
                                        ctx.setShow(true);
                                    }, children: _jsx(VerticalSplit, {}) }), _jsx(IconButton, { component: Link, to: `/commissions?id=${val === null || val === void 0 ? void 0 : val.str_id}`, target: "_blank", children: _jsx(Launch, {}) })] })), data: vals }, "statement_data"));
                },
                render: (field, row, setter, collectionVals) => {
                    return row && Array.isArray(row.statement_data) ? (_jsx(DataPreviewTable, { label: "Commissions", data: row.statement_data, fields: [
                            {
                                label: 'Commission ID',
                                key: 'str_id',
                                formatter: (val) => (_jsxs(Box, { sx: { whiteSpace: 'nowrap' }, children: [_jsx(Typography, { variant: "body2", component: "span", children: `${val.substring(0, 10)}...` }), _jsx(IconButton, { component: Link, to: `/commissions?id=${val}`, target: "_blank", children: _jsx(Launch, {}) })] })),
                            },
                            {
                                label: 'Processing date',
                                key: 'processing_date',
                                formatter: Formatter.date,
                            },
                            {
                                label: 'Compensation type',
                                key: 'compensation_type',
                            },
                            {
                                label: 'Commission rate',
                                key: 'commission_rate',
                            },
                            {
                                label: 'Commission amount',
                                key: 'commission_amount',
                            },
                            {
                                label: 'Agent commissions',
                                key: 'agent_commissions',
                                formatter: (val, collectionVals = [], options) => {
                                    if (!val || !Array.isArray(collectionVals))
                                        return '';
                                    return (_jsx(Box, { children: Object.entries(val)
                                            .filter(([k, v]) => k !== 'total')
                                            .map(([k, v]) => {
                                            var _a;
                                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => (e === null || e === void 0 ? void 0 : e.str_id) === k)) !== null && _a !== void 0 ? _a : { id: undefined };
                                            return contact.id ? (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                                    account_id: options === null || options === void 0 ? void 0 : options.account_id,
                                                })}: ${Formatter.currency(v)}`, sx: { m: 0.25 } }, contact === null || contact === void 0 ? void 0 : contact.id)) : (_jsx(Skeleton, {}, k));
                                        }) }));
                                },
                            },
                            {
                                label: 'Agent commission log',
                                key: 'agent_commissions_log',
                                formatter: (val, collectionVals = [], options) => {
                                    if (!val || !Array.isArray(collectionVals))
                                        return '';
                                    return (_jsx(Box, { children: Object.entries(val)
                                            .filter(([k, v]) => k !== 'total')
                                            .map(([k, v]) => {
                                            var _a;
                                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {};
                                            return (_jsxs(Box, { children: [Formatter.contact(contact, {
                                                        account_id: options === null || options === void 0 ? void 0 : options.account_id,
                                                    }), _jsx("br", {}), v.map((e, i) => (_jsx(CommissionCalcLog, { commissionProfile: e }, i)))] }, k));
                                        }) }));
                                },
                            },
                        ], collectionVals: collectionVals })) : null;
                },
            },
            children_report_data: {
                label: 'Linked policies',
                required: false,
                enabled: true,
                type: 'custom',
                tableFormatter: (vals) => (_jsx(Box, { children: vals === null || vals === void 0 ? void 0 : vals.map((val) => (_jsx(Chip, { label: val.str_id, clickable: true, component: "a", href: `/policies?id=${val.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.str_id))) }, "children_report_data")),
                render: (field, row) => {
                    return row && Array.isArray(row.children_report_data) ? (_jsxs(Box, { children: [_jsx(Typography, { children: "Linked policies" }), row.children_report_data.map((val) => (_jsx(Chip, { label: val.str_id, clickable: true, component: "a", href: `/policies?id=${val.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.str_id)))] }, "children_report_data")) : null;
                },
                readOnly: true,
            },
            type: {
                id: 'type',
                label: 'Type',
                readOnly: true,
                hidden: true,
            },
            tags: {
                label: 'Tags',
                enabled: true,
                normalizer: (val) => {
                    var _a;
                    if (Array.isArray(val) && val.length === 0)
                        return [];
                    if (typeof val === 'string')
                        return (_a = val === null || val === void 0 ? void 0 : val.split(',').map((s) => s.trim())) !== null && _a !== void 0 ? _a : [];
                    return val;
                },
                formatter: (val) => (Array.isArray(val) ? val.join(', ') : val),
                bulkEdit: true,
            },
            document_id: {
                label: 'Document',
                matches: ['document id', 'document'],
                enabled: true,
                required: false,
                type: 'dynamic-select',
                table: 'documents',
                queryParamValue: 'report',
                queryParamName: 'type',
                formatter: (val, collectionVals = []) => {
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((datum) => datum.str_id === val);
                        return datum ? (_jsx(Chip, { label: `${datum.filename}`, clickable: true, component: "a", href: `/documents?id=${datum === null || datum === void 0 ? void 0 : datum.str_id}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
                optionFormatter: (option) => `${option.filename}`,
                optionValuer: (option) => option.str_id,
                bulkEdit: true,
            },
            processing_status: {
                label: 'Processing status',
                matches: ['new', 'processed', 'frozen'],
                type: 'select',
                enabled: true,
                bulkEdit: true,
            },
            first_payment_date: {
                label: 'First payment date',
                enabled: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
                type: 'date',
                bulkEdit: true,
            },
            first_processed_date: {
                label: 'First processed date',
                enabled: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
                type: 'date',
                bulkEdit: true,
            },
            commission_profile_id: {
                label: 'Comp profile',
                enabled: true,
                required: false,
                readOnly: true,
                type: 'dynamic-select',
                table: 'commission_schedules/agent_commission_schedule_profiles',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val &&
                        Array.isArray(collectionVals) &&
                        collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((compProfile) => compProfile.str_id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? (_jsx(Chip, { label: `${datum.name}`, clickable: true, component: "a", href: `/schedules/comp-profiles?id=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
                optionFormatter: (option) => option.name,
                optionValuer: (option) => option.str_id,
            },
            agent_payout_rate_override: {
                label: 'Agent payout rate override',
                table: 'contacts',
                enabled: true,
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, collectionVals = []) => {
                    return (_jsx(AgentCommissionsEdit, { data: row, setter: setter, field: field, dynamicSelects: collectionVals, percentageFormat: "percentage", disableConditions: false }, "agent_payout_rate_override"));
                },
                getWidth: ({ allRows, dynamicSelectData }) => {
                    const mapData = dynamicSelectData === null || dynamicSelectData === void 0 ? void 0 : dynamicSelectData.reduce((acc, curr) => {
                        acc[curr.str_id] = curr;
                        return acc;
                    }, {});
                    if (!mapData)
                        return 0;
                    let maxWidth = 0;
                    allRows.forEach((row) => {
                        if (row.agent_payout_rate_override) {
                            Object.entries(row.agent_payout_rate_override).forEach(([id, v]) => {
                                var _a;
                                const contact = mapData[id];
                                const label = `${Formatter.contact(contact, {
                                    account_id: (_a = this.options) === null || _a === void 0 ? void 0 : _a.account_id,
                                })}: ${Formatter.percentage(Number(v) / 100)}`;
                                const width = label.length * CHAR_WIDTH;
                                if (width > maxWidth) {
                                    maxWidth = width;
                                }
                            });
                        }
                    });
                    return maxWidth;
                },
                formatter: (val, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val)
                            .filter(([k, v]) => k !== 'total')
                            .map(([k, v]) => {
                            var _a, _b;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {};
                            return (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                    account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                })}: ${Formatter.percentage(Number(v) / 100)}`, sx: { m: 0.1 } }, contact.id));
                        }) }));
                },
                bulkEdit: true,
            },
            sync_id: {
                label: 'Sync id',
                enabled: true,
                type: FieldTypes.TEXT,
                bulkEdit: true,
            },
        };
        this.actions = [
            {
                id: 'copy_link',
                label: 'Copy link',
                onClick: (row) => {
                    copy(`${window.location.origin}/policies?id=${row.str_id}`);
                },
            },
        ];
        // Add field key as field property
        // Delete fields that are not enabled
        Object.entries(this.fields).forEach(([k, v]) => {
            this.fields[k].id = k;
            if (!v.enabled) {
                delete this.fields[k];
            }
        });
        this.fieldsCollapsed = Object.values(this.fields)
            .map((field) => field.matches)
            .flat();
        this.outstandingFieldsInMobileView = [
            'policy_id',
            'customer_name',
            'effective_date',
            'carrier_name',
            'premium_amount',
        ];
    }
}
_Reports_mode = new WeakMap(), _Reports_strings = new WeakMap();
export default Reports;
