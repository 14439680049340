import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Chip, IconButton, Tooltip } from '@mui/material';
import { isValidElement, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { SelectDbValsShowMore } from './SelectDbValueShowMore';
export const Cell = memo(({ column, dynamicSelects, rowData, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const navigate = useNavigate();
    const { copyable, onClick, id, tableFormatter, table, type, itemOnClick, linker, delimiter, id2, options, } = column;
    const headerKey = (_a = column.keyAs) !== null && _a !== void 0 ? _a : column.id;
    const formatter = (x, dynamicSelects) => {
        if (tableFormatter instanceof Function) {
            return tableFormatter(x, rowData, dynamicSelects, column);
        }
        if (column.formatter instanceof Function) {
            if (dynamicSelects) {
                // TODO: Allow formatters to be applied when values are null
                if (!x)
                    return null;
                if (isValidElement(x))
                    return x;
                if (isValidElement(column.formatter(x, dynamicSelects)))
                    return column.formatter(x, dynamicSelects);
                return (_jsx(Chip, { label: column.formatter(x, dynamicSelects), sx: { m: 0.25 }, clickable: typeof linker === 'function', component: typeof linker === 'function' ? Link : 'div', to: typeof linker === 'function' ? linker(x) : undefined }));
            }
            if (x instanceof Object) {
                const res = column.formatter(x, rowData, navigate);
                if (res === null)
                    return null;
                return typeof res === 'object' && !isValidElement(res) ? '---' : res;
            }
            return column.formatter(x, rowData, navigate);
        }
        if (x instanceof Object && !isValidElement(x)) {
            return JSON.stringify(x);
        }
        if (type === 'boolean') {
            return x ? 'Yes' : 'No';
        }
        return x;
    };
    let getter = column.getter;
    if (!getter) {
        getter =
            type === 'select' && typeof (options === null || options === void 0 ? void 0 : options[0]) === 'object'
                ? (x) => { var _a; return (_a = options.find((option) => option.id === x[headerKey])) === null || _a === void 0 ? void 0 : _a.label; }
                : (x) => x[headerKey];
    }
    return (_jsxs(Box, { sx: {
            whiteSpace: copyable ? 'nowrap' : 'normal',
            cursor: onClick ? 'pointer' : 'default',
        }, onClick: (e) => {
            e.stopPropagation();
            if (onClick instanceof Function) {
                onClick(rowData);
            }
        }, children: [_jsx(Box, { sx: {
                    display: copyable ? 'inline-flex' : 'inherit',
                }, children: rowData[headerKey] !== undefined ? (Array.isArray(rowData[column.id]) && !tableFormatter ? (id === 'notes' ? (_jsx(Tooltip, { title: _jsx(Box, { children: rowData[id].map((item) => (_jsx(Box, { sx: { my: 0.5 }, children: item }, item))) }), children: _jsx("span", { style: { whiteSpace: 'nowrap' }, children: rowData[id].length
                            ? `⚠️ ${rowData[id].length} conflicts`
                            : null }) })) : (_jsx(SelectDbValsShowMore, { data: rowData[id].map((item, i) => {
                        var _a, _b;
                        const formattedVal = formatter === null || formatter === void 0 ? void 0 : formatter(item, (_b = (_a = dynamicSelects[table]) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : dynamicSelects[table]);
                        if (formattedVal === null)
                            return null;
                        return (_jsxs("span", { children: [type === 'dynamic-select' ? (formattedVal) : isValidElement(formattedVal) ? (formattedVal) : (_jsx(Chip, { label: formattedVal, sx: {
                                        m: 0.25,
                                        cursor: itemOnClick || linker ? 'pointer' : 'default',
                                        maxWidth: 500,
                                    }, onClick: (e) => {
                                        e.stopPropagation();
                                        if (typeof itemOnClick === 'function')
                                            itemOnClick(item);
                                    }, clickable: typeof linker === 'function', component: typeof linker === 'function' ? Link : 'div', to: typeof linker === 'function'
                                        ? linker(item)
                                        : undefined }, item)), delimiter && i < rowData[id].length - 1 && delimiter] }, typeof item === 'object' ? JSON.stringify(item) : item));
                    }) }))) : (formatter === null || formatter === void 0 ? void 0 : formatter(id2 && !(rowData[headerKey] instanceof Object)
                    ? ((_d = (_c = (_b = rowData[headerKey]) === null || _b === void 0 ? void 0 : _b[id2]) === null || _c === void 0 ? void 0 : _c[headerKey]) !== null && _d !== void 0 ? _d : (_f = (_e = rowData[headerKey]) === null || _e === void 0 ? void 0 : _e[id2]) === null || _f === void 0 ? void 0 : _f[id2])
                    : getter === null || getter === void 0 ? void 0 : getter(rowData), table
                    ? ((_h = (_g = dynamicSelects[table]) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : dynamicSelects[table])
                    : undefined))) : ('') }), copyable && (_jsx(IconButton, { onClick: (e) => {
                    var _a, _b, _c, _d, _e;
                    e.stopPropagation();
                    const valUnformatted = id2 && !(rowData[headerKey] instanceof Object)
                        ? ((_c = (_b = (_a = rowData[headerKey]) === null || _a === void 0 ? void 0 : _a[id2]) === null || _b === void 0 ? void 0 : _b[headerKey]) !== null && _c !== void 0 ? _c : (_e = (_d = rowData[headerKey]) === null || _d === void 0 ? void 0 : _d[id2]) === null || _e === void 0 ? void 0 : _e[id2])
                        : getter === null || getter === void 0 ? void 0 : getter(rowData);
                    const valFormatted = formatter === null || formatter === void 0 ? void 0 : formatter(valUnformatted, rowData);
                    if (typeof valFormatted === 'string') {
                        copy(valFormatted);
                    }
                    else {
                        copy(valUnformatted);
                    }
                }, sx: {
                    opacity: 0,
                    '&:hover': { opacity: 1 },
                }, children: _jsx(ContentCopy, {}) }))] }));
});
