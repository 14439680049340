export var CompGridRateFields;
(function (CompGridRateFields) {
    CompGridRateFields["ALL"] = "all";
    CompGridRateFields["CARRIER_RATE"] = "carrier_rate";
    CompGridRateFields["HOUSE_RATE"] = "house_rate";
    CompGridRateFields["TOTAL_RATE"] = "total_rate";
})(CompGridRateFields || (CompGridRateFields = {}));
export var ContactPayableStatuses;
(function (ContactPayableStatuses) {
    ContactPayableStatuses["PAYABLE"] = "payable";
    ContactPayableStatuses["NON_PAYABLE"] = "non_payable";
})(ContactPayableStatuses || (ContactPayableStatuses = {}));
export var CompReportDateFilter;
(function (CompReportDateFilter) {
    CompReportDateFilter["ProcessingDate"] = "processing_date";
    CompReportDateFilter["PaymentDate"] = "payment_date";
})(CompReportDateFilter || (CompReportDateFilter = {}));
export var CompReportPrefixConfig;
(function (CompReportPrefixConfig) {
    CompReportPrefixConfig["Default"] = "default_prefix";
    CompReportPrefixConfig["Custom"] = "custom_prefix";
})(CompReportPrefixConfig || (CompReportPrefixConfig = {}));
export var ProductTypes;
(function (ProductTypes) {
    ProductTypes["LIFE"] = "Life";
    ProductTypes["ANNUITY"] = "Annuity";
})(ProductTypes || (ProductTypes = {}));
export var CompensationTypes;
(function (CompensationTypes) {
    // COMMISSION = 'Commission',
    // OVERRIDE = 'Override',
    // EXCESS = 'Excess',
    // RENEWAL_COMMISSION = 'Renewal Commission',
    // TRAIL = 'Trail',
    // Use TG definitions until we have a config for this
    CompensationTypes["COMMISSION"] = "FYC";
    CompensationTypes["OVERRIDE"] = "FYC";
    CompensationTypes["EXCESS"] = "Excess";
    CompensationTypes["RENEWAL_COMMISSION"] = "Renew";
    CompensationTypes["TRAIL"] = "Trail";
})(CompensationTypes || (CompensationTypes = {}));
export var CompReportViewTypes;
(function (CompReportViewTypes) {
    CompReportViewTypes["PRODUCER_VIEW"] = "prod-view";
    CompReportViewTypes["ADMIN_VIEW"] = "admin-view";
})(CompReportViewTypes || (CompReportViewTypes = {}));
export var FiltersOperators;
(function (FiltersOperators) {
    // TODO: Add directional operators (e.g. within one year before | within one year after)
    FiltersOperators["EQ"] = "eq";
    FiltersOperators["NEQ"] = "neq";
    FiltersOperators["EQNUM"] = "eqnum";
    FiltersOperators["NEQNUM"] = "neqnum";
    FiltersOperators["GT"] = "gt";
    FiltersOperators["LT"] = "lt";
    FiltersOperators["GTE"] = "gte";
    FiltersOperators["LTE"] = "lte";
    FiltersOperators["CONTAINS"] = "contains";
    FiltersOperators["NCONTAINS"] = "ncontains";
    FiltersOperators["CONTAINEDIN"] = "containedin";
    FiltersOperators["NCONTAINEDIN"] = "ncontainedin";
    FiltersOperators["STARTSWITH"] = "startswith";
    FiltersOperators["ENDSWITH"] = "endswith";
    FiltersOperators["BEFORE"] = "before";
    FiltersOperators["BEFORE_EQUALS"] = "beforeEquals";
    FiltersOperators["AFTER"] = "after";
    FiltersOperators["AFTER_EQUALS"] = "afterEquals";
    FiltersOperators["IS_EMPTY"] = "isEmpty";
    FiltersOperators["IS_WRITING_AGENT"] = "Is writing agent";
    FiltersOperators["NOT_WRITING_AGENT"] = "Not writing agent";
    FiltersOperators["WITHIN_ONE_YEAR"] = "withinOneYear";
    FiltersOperators["AT_LEAST_ONE_YEAR"] = "atLeastOneYear";
    FiltersOperators["CUSTOM"] = "custom";
    FiltersOperators["IS_NOT_EMPTY"] = "isNotEmpty";
})(FiltersOperators || (FiltersOperators = {}));
export var DataProcessingAction;
(function (DataProcessingAction) {
    DataProcessingAction["SET_FIELD_VALUE"] = "set_field_value";
    DataProcessingAction["SET_VALUE_FROM_FIELD"] = "set_value_from_field";
    DataProcessingAction["SET_BY_CUSTOM_RULE"] = "set_by_custom_rule";
})(DataProcessingAction || (DataProcessingAction = {}));
export var DataEntities;
(function (DataEntities) {
    DataEntities["COMMISSIONS"] = "Commissions";
    DataEntities["POLICIES"] = "Policies";
})(DataEntities || (DataEntities = {}));
export var ContactStatuses;
(function (ContactStatuses) {
    ContactStatuses["INACTIVE"] = "Inactive";
    // TODO: Add more statuses
})(ContactStatuses || (ContactStatuses = {}));
export var AccessTypes;
(function (AccessTypes) {
    AccessTypes["GLOBAL"] = "global";
    AccessTypes["ACCOUNT"] = "account";
})(AccessTypes || (AccessTypes = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "asc";
    SortOrder["DESC"] = "desc";
})(SortOrder || (SortOrder = {}));
export var ProcessorReviewStatuses;
(function (ProcessorReviewStatuses) {
    ProcessorReviewStatuses["None"] = "";
    ProcessorReviewStatuses["Draft"] = "draft";
    ProcessorReviewStatuses["Approved"] = "approved";
    ProcessorReviewStatuses["InReview"] = "in_review";
    ProcessorReviewStatuses["NeedsUpdate"] = "needs_update";
})(ProcessorReviewStatuses || (ProcessorReviewStatuses = {}));
export const ProcessorReviewStatusesLabels = {
    [ProcessorReviewStatuses.None]: '',
    [ProcessorReviewStatuses.Draft]: 'Draft',
    [ProcessorReviewStatuses.Approved]: 'Approved',
    [ProcessorReviewStatuses.InReview]: 'In review',
    [ProcessorReviewStatuses.NeedsUpdate]: 'Needs update',
};
export var ProcessorStatuses;
(function (ProcessorStatuses) {
    ProcessorStatuses["NEW"] = "new";
    ProcessorStatuses["PROCESSED"] = "processed";
})(ProcessorStatuses || (ProcessorStatuses = {}));
export const DocumentStatuses = {
    NEW: 'new',
    PROCESSING: 'processing',
    PENDING_REVIEW: 'pending_review',
    PROCESSED: 'processed',
};
export const DocumentStatusesLabels = {
    [DocumentStatuses.NEW]: 'New',
    [DocumentStatuses.PROCESSING]: 'Processing',
    [DocumentStatuses.PENDING_REVIEW]: 'Pending review',
    [DocumentStatuses.PROCESSED]: 'Processed',
};
export var ProcessorSelectorStatuses;
(function (ProcessorSelectorStatuses) {
    ProcessorSelectorStatuses["SUCCESS"] = "success";
    ProcessorSelectorStatuses["PARTIAL"] = "partial";
    ProcessorSelectorStatuses["ERROR"] = "error";
})(ProcessorSelectorStatuses || (ProcessorSelectorStatuses = {}));
export var TransactionStatuses;
(function (TransactionStatuses) {
    TransactionStatuses["DRAFT"] = "Draft";
    TransactionStatuses["APPROVED"] = "Approved";
    TransactionStatuses["PAID"] = "Paid";
    TransactionStatuses["SETTLEMENT"] = "Settlement";
})(TransactionStatuses || (TransactionStatuses = {}));
export const TransactionStatusesLabels = {
    [TransactionStatuses.DRAFT]: 'Draft',
    [TransactionStatuses.APPROVED]: 'Approved',
    [TransactionStatuses.PAID]: 'Paid',
    [TransactionStatuses.SETTLEMENT]: 'Settlement',
};
export const TransactionStatusesOptions = Object.values(TransactionStatuses).map((status) => ({
    value: status,
    label: TransactionStatusesLabels[status],
}));
export var SavedReportStatuses;
(function (SavedReportStatuses) {
    SavedReportStatuses["DRAFT"] = "Draft";
    SavedReportStatuses["APPROVED"] = "Approved";
    SavedReportStatuses["PAID"] = "Paid";
})(SavedReportStatuses || (SavedReportStatuses = {}));
export const SavedReportStatusesLabels = {
    [SavedReportStatuses.DRAFT]: 'Draft',
    [SavedReportStatuses.APPROVED]: 'Approved',
    [SavedReportStatuses.PAID]: 'Paid',
};
export const SavedReportStatusOptions = Object.values(SavedReportStatuses).map((status) => ({
    value: status,
    label: SavedReportStatusesLabels[status],
}));
export var ReportGroupsStatuses;
(function (ReportGroupsStatuses) {
    ReportGroupsStatuses["DRAFT"] = "Draft";
    ReportGroupsStatuses["APPROVED"] = "Approved";
    ReportGroupsStatuses["PAID"] = "Paid";
    ReportGroupsStatuses["IN_PROGRESS"] = "In_progress";
})(ReportGroupsStatuses || (ReportGroupsStatuses = {}));
export const ReportGroupsStatusesLabels = {
    [ReportGroupsStatuses.DRAFT]: 'Draft',
    [ReportGroupsStatuses.APPROVED]: 'Approved',
    [ReportGroupsStatuses.PAID]: 'Paid',
    [ReportGroupsStatuses.IN_PROGRESS]: 'In progress',
};
export const ReportGroupsStatusOptions = Object.values(ReportGroupsStatuses).map((status) => ({
    value: status,
    label: ReportGroupsStatusesLabels[status],
}));
export var AgentCommissionsStatuses;
(function (AgentCommissionsStatuses) {
    AgentCommissionsStatuses["NONE"] = "";
    AgentCommissionsStatuses["DRAFT"] = "Draft";
    AgentCommissionsStatuses["REVIEWED"] = "Reviewed";
    AgentCommissionsStatuses["MANUAL"] = "Manual";
    AgentCommissionsStatuses["OFFSET"] = "Offset";
    AgentCommissionsStatuses["NO_PAYMENT"] = "No_payment";
    AgentCommissionsStatuses["APPROVED"] = "Approved";
    AgentCommissionsStatuses["PAID"] = "Paid";
    AgentCommissionsStatuses["REJECTED"] = "Rejected";
})(AgentCommissionsStatuses || (AgentCommissionsStatuses = {}));
export const AgentCommissionsStatusesLabels = {
    [AgentCommissionsStatuses.NONE]: '(Blank)',
    [AgentCommissionsStatuses.DRAFT]: 'Draft',
    [AgentCommissionsStatuses.REVIEWED]: 'Reviewed',
    [AgentCommissionsStatuses.MANUAL]: 'Manual',
    [AgentCommissionsStatuses.OFFSET]: 'Offset',
    [AgentCommissionsStatuses.NO_PAYMENT]: 'No payment',
    [AgentCommissionsStatuses.APPROVED]: 'Approved',
    [AgentCommissionsStatuses.PAID]: 'Paid',
    [AgentCommissionsStatuses.REJECTED]: 'Rejected',
};
export const AgentCommissionsStatusOptions = Object.values(AgentCommissionsStatuses).map((status) => ({
    value: status,
    label: AgentCommissionsStatusesLabels[status],
}));
export var ExtractTableStatuses;
(function (ExtractTableStatuses) {
    ExtractTableStatuses["SUCCESS"] = "Success";
    ExtractTableStatuses["SUCCEEDED"] = "SUCCEEDED";
})(ExtractTableStatuses || (ExtractTableStatuses = {}));
export var ImportStatuses;
(function (ImportStatuses) {
    ImportStatuses["NONE"] = "none";
    ImportStatuses["REUQEST_REVIEW"] = "request_review";
    ImportStatuses["AUTO_IMPORT"] = "auto_import";
})(ImportStatuses || (ImportStatuses = {}));
export var ImportMethod;
(function (ImportMethod) {
    ImportMethod["MANUAL"] = "manual";
    ImportMethod["AUTO"] = "auto";
})(ImportMethod || (ImportMethod = {}));
