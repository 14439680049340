var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Button, Tooltip, useMediaQuery } from '@mui/material';
import { AccountIds, WorkerNames } from 'common/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AddLink, CalculateOutlined, MergeOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useAccountStore, useRoleStore } from '@/store';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import Statements from '@/services/Statements';
import { Roles } from '@/types';
import API from '@/services/API';
import { ReconciliationConfirmProvider } from '@/contexts/ReconciliationConfirmProvider';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import useSnackbar from '@/contexts/useSnackbar';
import ReconcileEdit from '@/components/CommissionsDataView/ReconcileEdit';
import { LoadingContext } from '@/contexts/LoadingContext';
import GroupingEdit from '@/components/CommissionsDataView/Grouping';
const exportOptions = [
    {
        id: 'export',
        label: 'Export',
        options: {},
    },
    {
        id: 'export-producer-view',
        label: 'Export producer view',
        options: {
            producer_view: true,
            disabled: true,
            tooltip: 'Please select an Agent in the Agents filter to enable.',
        },
    },
];
const CommissionsDataView = ({ reportId = null }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const { role: [role], } = useContext(UIStateContext);
    const { selectedAccount } = useAccountStore();
    const { userRole } = useRoleStore();
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const statements = new Statements(mode, role, userRole, {
        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
    });
    const [showReconcile, setShowReconcile] = useState(false);
    const [showGrouping, setShowGrouping] = useState(false);
    const { setLoadingConfig } = useContext(LoadingContext);
    const [selectedStatment, setSelectedStatment] = useState(null);
    const { workerSyncedFields } = useSyncedFieldsNew();
    const hasAgencyIntegratorWorker = !!(workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[WorkerNames.AgencyIntegratorWorker]);
    const syncService = API.getMutation('data_processing/sync', 'POST');
    const policyRatesPatcher = API.getMutation('report_data/update-payout-rates', 'PATCH');
    const { showSnackbar } = useSnackbar();
    const [selectedData, setSelectedData] = useState([]);
    const onBulkSync = (params) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            let result = null;
            if (hasAgencyIntegratorWorker) {
                const lookupData = new Map(selectedData.map((item) => [item.id, item]));
                const policyNumbers = params.ids.map((id) => { var _a; return (_a = lookupData.get(id)) === null || _a === void 0 ? void 0 : _a.policy_id; });
                if (!policyNumbers.length) {
                    return;
                }
                result = yield syncService.mutateAsync({
                    entities: ['policies'],
                    policyNumbers: policyNumbers,
                    sync: true,
                    worker: WorkerNames.AgencyIntegratorWorker,
                });
            }
            if (!(result === null || result === void 0 ? void 0 : result.success)) {
                showSnackbar(`Sync failed: ${result === null || result === void 0 ? void 0 : result.message}`, 'error');
            }
            else {
                showSnackbar('Sync successfully', 'success');
            }
        }
        catch (error) {
            console.error(error);
            showSnackbar('Sync failed', 'error');
        }
    });
    (_a = statements.actions) === null || _a === void 0 ? void 0 : _a.push({
        id: 'reconcile',
        label: 'Manual reconcile',
        enabled: (row) => !['Paid', 'Approved', 'Manual'].includes(row.agent_commissions_status),
        onClick: (row) => {
            setSelectedStatment(row);
            setShowReconcile(true);
        },
    }, {
        id: 'update_policy_payout_rates',
        label: 'Update policy with payout rates',
        onClick: (row) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield policyRatesPatcher.mutateAsync({
                    statement_id: row.id,
                });
                if (response) {
                    showSnackbar('Policy payout rates updated successfully', 'success');
                }
            }
            catch (error) {
                const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
                showSnackbar(errorMessage, 'error');
            }
        }),
    });
    const [filterCacheKey, setFilterCacheKey] = useState([]);
    const queryClient = useQueryClient();
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const pageSettingFields = isMobile
        ? (_c = (_b = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _b === void 0 ? void 0 : _b.commissions) === null || _c === void 0 ? void 0 : _c.outstandingMobileFields
        : (_e = (_d = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _d === void 0 ? void 0 : _d.commissions) === null || _e === void 0 ? void 0 : _e.fields;
    const newFields = (pageSettingFields !== null && pageSettingFields !== void 0 ? pageSettingFields : []).reduce((acc, cur) => {
        acc[cur] = Object.assign({}, statements.fields[cur]);
        if (role === 'admin' &&
            (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) === AccountIds.TRANSGLOBAL) {
            if (cur == 'agent_commissions_log') {
                acc.expected_result = statements.fields.expected_result;
            }
        }
        return acc;
    }, {});
    if (Object.keys(newFields).length > 0)
        statements.fields = newFields;
    statements.dateFilters = statements.dateFilters.filter((filter) => {
        return pageSettingFields === null || pageSettingFields === void 0 ? void 0 : pageSettingFields.includes(filter.filterFieldId);
    });
    if ((_g = (_f = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _f === void 0 ? void 0 : _f.commissions) === null || _g === void 0 ? void 0 : _g.page_label) {
        statements.label = (_j = (_h = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _h === void 0 ? void 0 : _h.commissions) === null || _j === void 0 ? void 0 : _j.page_label;
    }
    if (isFetchedAccountSettings &&
        ((_l = (_k = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _k === void 0 ? void 0 : _k.commissions) === null || _l === void 0 ? void 0 : _l.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/policies" })
        // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
        //   <Typography variant="h5">No results</Typography>
        // </Box>
        );
    }
    const [searchParams, _] = useSearchParams();
    useEffect(() => {
        // Cancel previous query when searchParams or selectedAccount changes, avoid previous query from returning stale data and causing UI mismatch
        setFilterCacheKey((prev) => {
            const cacheKey = [
                selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                // Filter out pagination & order params from searchParams
                new URLSearchParams(Array.from(searchParams.entries()).filter(([key]) => !['limit', 'page', 'sort', 'orderBy'].includes(key))).toString(),
            ];
            if (prev.length > 0 && prev.join() !== cacheKey.join()) {
                queryClient.cancelQueries({ queryKey: prev });
            }
            return cacheKey;
        });
    }, [queryClient, searchParams, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    const { data: filters } = useQuery({
        queryKey: filterCacheKey,
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            const url = `${process.env.REACT_APP_API}/api/statement_data/filters?${new URLSearchParams(searchParams).toString()}`;
            const res = yield fetch(url, {
                method: 'GET',
                headers: yield API.getHeaders(),
            });
            return res.json();
        }),
    });
    const handleCreatePolicy = (commissionData) => {
        // Extract relevant fields from commission data to pre-populate report
        const reportData = {
            agent_name: commissionData.agent_name,
            customer_name: commissionData.customer_name,
            contacts: commissionData.contacts,
            policy_id: commissionData.policy_id,
            effective_date: commissionData.effective_date,
            geo_state: commissionData.geo_state,
            issue_age: commissionData.issue_age,
            notes: commissionData.notes,
            payment_mode: commissionData.payment_mode,
            product_name: commissionData.product_name,
            product_type: commissionData.product_type,
            premium_amount: commissionData.premium_amount,
            transaction_type: commissionData.transaction_type,
            writing_carrier_name: commissionData.writing_carrier_name,
            commissionable_premium_amount: commissionData.commissionable_premium_amount,
            // Add any other fields that should be copied from commission to report
        };
        // Navigate to reports page with pre-populated data
        navigate('/policies?m=edit', {
            state: {
                mode: 'edit',
                prefillData: reportData,
            },
        });
    };
    const BulkReconcile = ({ key, isWorkingToolbar }) => {
        return (_jsx(LoadingButton, { loading: isWorkingToolbar, onClick: () => {
                setShowReconcile(true);
            }, color: "primary", children: _jsx(Tooltip, { title: "Bulk reconciliations", children: _jsx(AddLink, {}) }) }, key));
    };
    const BulkGroupDedupe = ({ key, isWorkingToolbar }) => {
        return (_jsx(LoadingButton, { loading: isWorkingToolbar, onClick: () => setShowGrouping(true), children: _jsx(Tooltip, { title: "Bulk group records", children: _jsx(MergeOutlined, {}) }) }, key));
    };
    const BulkCompCalc = () => {
        return (_jsx(Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const commissionIds = selectedStatment
                        ? [selectedStatment.id]
                        : selectedData.map((r) => r.id);
                    setLoadingConfig({
                        loading: true,
                        message: 'Calculating comp...',
                    });
                    const response = yield axios.post(`${process.env.REACT_APP_API}/api/data_processing/commissions/agents`, {
                        isSync: true,
                        useGroupedCommissions: true,
                        ids: commissionIds,
                    }, {
                        headers: yield API.getHeaders(),
                    });
                    const data = response.data;
                    if (response.status === 200 && data.statusText) {
                        showSnackbar('Comp calc successfully', 'success');
                        queryClient.invalidateQueries(filterCacheKey);
                    }
                    else {
                        showSnackbar(`Comp calc failed: ${data === null || data === void 0 ? void 0 : data.message}`, 'error');
                    }
                }
                catch (error) {
                    console.error(error);
                    showSnackbar('Comp calc failed', 'error');
                }
                finally {
                    setLoadingConfig({
                        loading: false,
                        message: '',
                    });
                }
            }), color: "primary", children: _jsx(Tooltip, { title: "Calculate comp", children: _jsx(CalculateOutlined, {}) }) }));
    };
    return selectedAccount && isFetchedAccountSettings ? (_jsxs(ReconciliationConfirmProvider, { mode: mode, children: [selectedStatment || selectedData.length ? (_jsxs(_Fragment, { children: [_jsx(ReconcileEdit, { open: showReconcile, onClose: () => {
                            setShowReconcile(false);
                            setSelectedStatment(null);
                        }, statementIds: selectedStatment
                            ? [selectedStatment.id]
                            : selectedData.map((r) => r.id) }), _jsx(GroupingEdit, { open: showGrouping, onClose: () => setShowGrouping(false), statements: selectedData, refetch: () => queryClient.invalidateQueries(filterCacheKey) })] })) : null, _jsx(EnhancedDataView, { reportId: reportId, dataSpec: statements, 
                // TODO: Refactor type after EnhancedDataView is refactored
                actions: statements.actions, actionsEnabled: () => true, filters: filters, 
                // TODO: Refactor type after EnhancedDataView is refactored
                exportOptions: exportOptions, outstandingMobileFields: (_o = (_m = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _m === void 0 ? void 0 : _m.commissions) === null || _o === void 0 ? void 0 : _o.outstandingMobileFields, setSelectedData: setSelectedData, onBulkSync: (hasAgencyIntegratorWorker
                    ? (ids) => onBulkSync({ ids })
                    : undefined), bulkActions: [BulkReconcile, BulkCompCalc, BulkGroupDedupe], enableSaves: true, showTotals: true, 
                // TODO (frank.santillan): Move to settings after we migrate reconciliation / commissions / policies to pages_settings.
                readOnly: userRole === Roles.PRODUCER, 
                // TODO: Refactor type after EnhancedDataView is refactored
                extraFormActions: [
                    {
                        label: 'Create policy',
                        onClick: handleCreatePolicy,
                        variant: 'text',
                        showInEdit: true, // Only show in edit mode
                    },
                ] })] })) : null;
};
export default CommissionsDataView;
