export const Colors = {
  SelectedHover: 'rgba(33, 150, 243, 0.12)',
  Selected: 'rgba(33, 150, 243, 0.08)',
  Hover: 'rgba(0, 0, 0, 0.04)',
};

export const CheckboxWidth = 48;

export const CHAR_WIDTH = 8.5;

export const ColNumberWidth = 32;
