var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Add, DeleteOutline, DownloadOutlined, Edit, LaunchOutlined, PlayArrow, SyncOutlined, } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import CommonFormatter from 'common/Formatter';
import { numberOrDefault, removeLeadingTrailingChar, tryDecodeURIComponent, } from 'common/helpers';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DocumentTypeLabels } from 'common/constants/documents';
import { DocumentStatuses, DocumentStatusesLabels } from 'common/globalTypes';
import { formatCurrency } from 'common/helpers/formatCurrency';
import { LoadingContext } from '@/contexts/LoadingContext';
import { UIStateContext } from '@/contexts/UIStateProvider';
import usePreviewParams from '@/contexts/usePreviewParams';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import UILabels from '@/services/UILabels';
import useAccountStore from '@/store/accountStore';
import { DocumentPreviewKeys } from '@/types';
import useSnackbar from '@/contexts/useSnackbar';
import { useFilters } from './useFilters';
import { SyncStatusList } from '@/components/admin/AdminDocumentsView/DocumentsView/DocumentsView';
import { CHAR_WIDTH } from '@/components/molecules/EnhancedTable/constants';
export const useDocumentViewDesc = ({ setProcessLoading, setActiveRow, setRowData, setShowUploadModal, setSync, setShowEdit, setShowConfirm, activeRowRef, }) => {
    var _a;
    const { filters } = useFilters();
    const exportPoster = API.getMutation('statement_data/export', 'POST', {
        rawData: true,
    });
    const { setShowPreview, setPreviewPath } = usePreviewParams();
    const { role: [role], } = useContext(UIStateContext);
    const { showSnackbar } = useSnackbar();
    const { setLoadingConfig } = useContext(LoadingContext);
    const documentPutter = API.getMutation('documents', 'PUT');
    const queryClient = useQueryClient();
    const { selectedAccount } = useAccountStore();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const labels = useMemo(() => new UILabels(mode), [mode]);
    // Hard coded for now, will be dynamic later
    const enableAccountId = ['W4kSrayZvmh26pGfYVrGE', 'fFF86XAy2Cu97xxra8lgA'];
    const isRiskTag = enableAccountId.includes((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) || '') ||
        ((_a = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('risktag'));
    const filePathFormatter = useCallback((filename, row) => filename ? (_jsx(Tooltip, { title: filename, enterNextDelay: 1000, children: _jsx(Button, { style: {
                width: 'max-content',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
                whiteSpace: 'break-spaces',
            }, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setPreviewPath(row.str_id, DocumentPreviewKeys.ORIGINAL);
                setShowPreview(true);
            }), children: _jsx("span", { style: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }, children: filename }) }) })) : ('--'), [setPreviewPath, setShowPreview]);
    const exportData = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        const params = {
            document_id: data.str_id,
            company_str_id: data.company_str_id,
        };
        try {
            setLoadingConfig({
                loading: true,
                message: 'Exporting...',
            });
            const res = yield exportPoster.mutateAsync(params);
            setLoadingConfig({
                loading: false,
                message: '',
            });
            const blob = res.data;
            saveAs(blob, `${removeLeadingTrailingChar(tryDecodeURIComponent(data.filename), '"')}`);
        }
        catch (error) {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(error.message || error, 'error');
        }
    }), [exportPoster, setLoadingConfig, showSnackbar]);
    const handleRunButtonClick = useCallback((row) => __awaiter(void 0, void 0, void 0, function* () {
        setActiveRow(row);
        activeRowRef.current = true;
        let interval;
        let timeout;
        try {
            yield new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    reject(new Error('Operation timed out after 60 seconds'));
                }, 60000);
                interval = setInterval(() => {
                    if (!activeRowRef.current) {
                        clearInterval(interval);
                        clearTimeout(timeout);
                        resolve();
                    }
                }, 100);
            });
        }
        catch (error) {
            console.error('Error during row processing:', error);
            clearInterval(interval);
            clearTimeout(timeout);
            activeRowRef.current = false;
        }
    }), [setActiveRow]);
    const markProcessed = useCallback((row) => __awaiter(void 0, void 0, void 0, function* () {
        const { company_str_id, file_type, id } = row;
        setProcessLoading(row.id);
        const res = yield documentPutter.mutateAsync({
            company_str_id,
            file_type,
            id,
            status: DocumentStatuses.PROCESSED,
        });
        setProcessLoading('');
        if (res.error) {
            showSnackbar(res.error, 'error');
        }
        else {
            queryClient.invalidateQueries();
        }
    }), [documentPutter, queryClient, setProcessLoading, showSnackbar]);
    const overrideFilePathFormatter = useCallback((s, row) => s ? (_jsx(Tooltip, { title: s, enterNextDelay: 1000, children: _jsx(Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setPreviewPath(row.str_id, DocumentPreviewKeys.OVERRIDE);
                setShowPreview(true);
            }), children: _jsx("span", { style: {
                    maxWidth: 240,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                    whiteSpace: 'break-spaces',
                }, children: s }) }) })) : (_jsx(Box, { sx: { ml: 4 }, children: _jsx(Tooltip, { title: "Add override file", placement: "right", children: _jsx(IconButton, { onClick: () => {
                    setRowData(row);
                    setShowUploadModal(true);
                }, children: _jsx(Add, { sx: { height: 18 } }) }) }) })), [setPreviewPath, setRowData, setShowPreview, setShowUploadModal]);
    const dataDesc = useMemo(() => ({
        useNewTable: false,
        label: labels.getLabel('documents', 'title'),
        table: 'documents',
        filters,
        fields: {
            filename: {
                label: 'File',
                enabled: true,
                copyable: true,
                formatter: filePathFormatter,
            },
            override_filename: {
                label: 'Override file',
                enabled: true,
                copyable: true,
                formatter: overrideFilePathFormatter,
                access: 'admin',
            },
            type: {
                label: 'Type',
                enabled: true,
                getWidth: ({ allRows }) => {
                    let maxWidth = 0;
                    allRows.forEach((row) => {
                        var _a;
                        if (row.type) {
                            const width = (((_a = DocumentTypeLabels[row.type]) === null || _a === void 0 ? void 0 : _a.length) || 0) * CHAR_WIDTH; // 20 for padding
                            maxWidth = Math.max(maxWidth, width);
                        }
                    });
                    return maxWidth;
                },
                formatter: (v) => DocumentTypeLabels[v],
            },
            companies: {
                label: 'Company',
                enabled: true,
                getWidth: ({ allRows }) => {
                    let maxWidth = 0;
                    allRows.forEach((row) => {
                        var _a, _b;
                        const width = (((_b = (_a = row.companies) === null || _a === void 0 ? void 0 : _a.company_name) === null || _b === void 0 ? void 0 : _b.length) || 0) * CHAR_WIDTH;
                        maxWidth = Math.max(maxWidth, width);
                    });
                    return maxWidth;
                },
                formatter: Formatter.getLinkChipFormatter('company_name', 'str_id', '/companies?id='),
            },
            statement_data: {
                label: 'Records',
                disableSort: true,
                enabled: true,
                getWidth: ({ allRows }) => {
                    let maxWidth = 0;
                    allRows.forEach((row) => {
                        if (row === null || row === void 0 ? void 0 : row.total_commission) {
                            const width = String(row.total_commission).length * CHAR_WIDTH + 30; // 30 for launch icon
                            maxWidth = Math.max(maxWidth, width);
                        }
                    });
                },
                formatter: (val, row) => {
                    var _a, _b;
                    const groupedCountInfoStrList = [];
                    const groupedCommissionInfoStrList = [];
                    if (val.groupedCountInfo) {
                        Object.entries(val.groupedCountInfo).forEach(([key, value]) => {
                            if (key !== 'NO_STATUS') {
                                groupedCountInfoStrList.push(`${key}: ${value}`);
                            }
                        });
                    }
                    if (val.groupedCommissionInfo) {
                        Object.entries(val.groupedCommissionInfo).forEach(([key, value]) => {
                            if (key !== 'NO_STATUS') {
                                groupedCommissionInfoStrList.push(`${key}: ${Formatter.currency(value)}`);
                            }
                        });
                    }
                    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '4px',
                                height: isRiskTag ? 90 : 'auto',
                                justifyContent: 'space-between',
                            }, children: [_jsxs(Box, { sx: { flex: 1 }, children: [!!val.total_count && (_jsxs(Box, { sx: {
                                                whiteSpace: 'nowrap',
                                            }, children: [_jsx("span", { children: +val.total_count }), groupedCountInfoStrList.length > 0 && (_jsxs("span", { style: {
                                                        color: '#555',
                                                        fontSize: 13,
                                                        marginLeft: 4,
                                                    }, children: ["(", groupedCountInfoStrList.toString(), ")"] }))] })), !!val.total_commission && (_jsxs(Box, { sx: {
                                                whiteSpace: 'nowrap',
                                            }, children: [_jsx("span", { children: Formatter.currency(val.total_commission) }), groupedCommissionInfoStrList.length > 0 && (_jsxs("span", { style: {
                                                        color: '#555',
                                                        fontSize: 13,
                                                        marginLeft: 4,
                                                    }, children: ["(", groupedCommissionInfoStrList.join(', '), ")"] }))] })), !val.total_commission && !val.total_count && '0'] }), _jsx(Box, { style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }, children: (val.total_commission > 0 || val.total_count > 0) && (_jsxs(_Fragment, { children: [_jsx(IconButton, { component: Link, to: `/${row.type === 'statement' ? 'commissions' : 'policies'}?document_id=${row.str_id}`, target: "_blank", sx: {
                                                    opacity: 0.5,
                                                    '&:hover': { opacity: 1 },
                                                    color: '#2196f3',
                                                }, children: _jsx(LaunchOutlined, {}) }), isRiskTag && (_jsxs(_Fragment, { children: [((_a = row === null || row === void 0 ? void 0 : row.statement_data) === null || _a === void 0 ? void 0 : _a.total_count) > 0 && (_jsx(Tooltip, { title: `${(row === null || row === void 0 ? void 0 : row.sync_id) ? `The document has been synced with statementId: ${row === null || row === void 0 ? void 0 : row.sync_id}, you can sync again to update` : `${(_b = row === null || row === void 0 ? void 0 : row.statement_data) === null || _b === void 0 ? void 0 : _b.total_count} statement entries will be synced to BenefitPoint`}`, placement: "top", children: _jsx(IconButton, { onClick: () => {
                                                                var _a;
                                                                return setSync({
                                                                    documentId: row.str_id,
                                                                    show: true,
                                                                    count: ((_a = row === null || row === void 0 ? void 0 : row.statement_data) === null || _a === void 0 ? void 0 : _a.total_count) || 0,
                                                                });
                                                            }, size: "small", sx: {
                                                                marginLeft: '4px',
                                                                opacity: 0.5,
                                                                '&:hover': { opacity: 1 },
                                                                color: '#2196f3',
                                                            }, children: _jsx(SyncOutlined, { color: row.sync_id ? 'disabled' : 'primary' }) }) })), _jsx(IconButton, { onClick: () => exportData(row), size: "small", sx: {
                                                            opacity: 0.5,
                                                            '&:hover': { opacity: 1 },
                                                            color: '#2196f3',
                                                        }, children: _jsx(DownloadOutlined, {}) })] }))] })) })] }) }));
                },
            },
            statement_amount: {
                label: 'Commission totals',
                enabled: true,
                disableSort: true,
                getWidth: ({ allRows }) => {
                    let maxWidth = 0;
                    allRows.forEach((row) => {
                        var _a, _b;
                        if ((_a = row.statement_data) === null || _a === void 0 ? void 0 : _a.total_commission) {
                            const text = `Commissions: ${formatCurrency((_b = row.statement_data) === null || _b === void 0 ? void 0 : _b.total_commission)}`;
                            const width = text.length * CHAR_WIDTH;
                            maxWidth = Math.max(maxWidth, width);
                        }
                    });
                    return maxWidth;
                },
                formatter: (v, row) => {
                    var _a, _b;
                    const statementData = row.statement_data;
                    const statementAmount = numberOrDefault(row.statement_amount, null, { toFixed: 2 });
                    const statementTotalAmount = numberOrDefault((_b = (_a = row.imports) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.statement_total_amount, null, { toFixed: 2 });
                    const totalCommissionAmount = numberOrDefault(statementData.total_commission, null, { toFixed: 2 });
                    if (row.status !== DocumentStatuses.PROCESSED) {
                        return (_jsxs(Box, { sx: {
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                flexDirection: 'column',
                            }, children: [statementAmount && (_jsx("span", { children: `Statement amount: ${Formatter.currency(statementAmount)}` })), statementTotalAmount && (_jsx("span", { children: `Statement total amount: ${Formatter.currency(statementTotalAmount)}` }))] }));
                    }
                    const statementAndCmsTotalIsEqual = isEqual(statementAmount, totalCommissionAmount);
                    const statementTotalAndCmsTotalIsEqual = isEqual(totalCommissionAmount, statementTotalAmount);
                    let matchesNode = _jsx(_Fragment, {});
                    if (statementAndCmsTotalIsEqual &&
                        statementTotalAndCmsTotalIsEqual &&
                        statementAmount !== null) {
                        const tip = 'Statement amount, statement total amount, and commission records all match';
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", children: _jsx("span", { children: `✅ ${Formatter.currency(statementAmount)}` }) }));
                    }
                    else if (statementAndCmsTotalIsEqual && totalCommissionAmount) {
                        const tip = (_jsxs("span", { children: ["Statement amount and commissions match", _jsx("br", {}), "(Statement total amount not available)"] }));
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", children: _jsx("span", { children: `✅ ${Formatter.currency(statementAmount)}` }) }));
                    }
                    else if (statementTotalAndCmsTotalIsEqual &&
                        totalCommissionAmount) {
                        const tip = 'Statement total amount and commission records match (Statement amount not available)';
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", children: _jsx("span", { children: `✅ ${Formatter.currency(statementTotalAmount)}` }) }));
                    }
                    else if (!statementAmount &&
                        !statementTotalAmount &&
                        !totalCommissionAmount) {
                        matchesNode = _jsx("span", { children: "No amounts available" });
                    }
                    else if (totalCommissionAmount &&
                        !statementTotalAmount &&
                        !statementAmount) {
                        matchesNode = (_jsx(Tooltip, { title: _jsxs("span", { children: ["Validation not available.", _jsx("br", {}), "Statement amount and/or statement total amount required."] }), placement: "right", children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Box, { sx: { fontSize: 12 }, children: "\u2139\uFE0F" }), _jsx(Box, { sx: {
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }, children: _jsx("span", { children: `Commissions: ${Formatter.currency(totalCommissionAmount)}` }) })] }) }));
                    }
                    else {
                        matchesNode = (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Box, { sx: { fontSize: 12 }, children: "\u274C" }), _jsxs(Box, { sx: {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }, children: [totalCommissionAmount ? (_jsx("span", { children: `Commissions: ${Formatter.currency(totalCommissionAmount)}` })) : (_jsx("span", { children: "No commission data" })), statementAmount ? (_jsx("span", { children: `Statement amount: ${Formatter.currency(statementAmount)}` })) : (_jsx("span", { children: "No statement amount" })), statementTotalAmount ? (_jsx("span", { children: `Statement total amount: ${Formatter.currency(statementTotalAmount)}` })) : (_jsx("span", { children: "No statement total amount" }))] })] }));
                    }
                    return _jsx(Box, { sx: { whiteSpace: 'nowrap' }, children: matchesNode });
                },
            },
            bank_total_amount: {
                label: 'Bank totals',
                enabled: true,
                disableSort: true,
                getWidth: ({ allRows }) => {
                    let maxWidth = 0;
                    allRows.forEach((row) => {
                        var _a, _b;
                        if ((_a = row.statement_data) === null || _a === void 0 ? void 0 : _a.total_commission) {
                            const text = `Commissions: ${formatCurrency((_b = row.statement_data) === null || _b === void 0 ? void 0 : _b.total_commission)}`;
                            const width = text.length * CHAR_WIDTH;
                            maxWidth = Math.max(maxWidth, width);
                        }
                    });
                    return maxWidth;
                },
                formatter: (v, row) => {
                    const bankTotalAmount = numberOrDefault(row.bank_total_amount, null, { toFixed: 2 });
                    return (_jsx(Box, { sx: {
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'column',
                        }, children: bankTotalAmount && (_jsx("span", { children: `Bank total: ${Formatter.currency(bankTotalAmount)}` })) }));
                },
            },
            created_by: {
                label: 'Imported total',
                enabled: true,
                disableSort: true,
                formatter: (v, row) => {
                    const list = row.imports;
                    if (list && list.length) {
                        const target = list[0];
                        return Formatter.currency(target === null || target === void 0 ? void 0 : target.summed_total_amount);
                    }
                },
                access: 'admin',
            },
            deposit_date: {
                label: 'Deposit date',
                enabled: true,
                formatter: (s) => {
                    return Formatter.date(s, {
                        format: 'MM/DD/YYYY',
                    });
                },
            },
            status: {
                label: 'Status',
                enabled: true,
                getWidth: ({ estimatedWidth }) => {
                    return estimatedWidth + 20; // 20 for padding
                },
                formatter: (val) => Formatter.statusChip(DocumentStatusesLabels[val], {
                    mapping: {
                        New: 'yellow',
                        Processing: 'blue',
                        'Pending review': 'deepBlue',
                        Processed: 'green',
                    },
                }),
            },
            method: {
                label: 'Method',
                enabled: true,
                access: 'admin',
                getWidth: ({ estimatedWidth }) => {
                    return estimatedWidth + 40; // 40 for padding
                },
                formatter: (val, row) => val ? (_jsx(Chip, { label: val, component: Link, to: `/documents/profiles?id=${row.profile_str_id}` })) : null,
            },
            notes: {
                label: 'Notes',
                enabled: true,
                getWidth: () => 200,
            },
            sync_id: {
                label: 'Synced',
                enabled: true,
                type: 'boolean',
                options: SyncStatusList,
                getWidth: () => 80,
            },
            imported_at: {
                label: 'Imported at',
                enabled: true,
                access: 'admin',
                getWidth: () => {
                    return 'MM/DD/YYYY hh:mmA'.length * CHAR_WIDTH;
                },
                formatter: (s, row) => {
                    if (!s) {
                        return '';
                    }
                    const uploadToImportTime = dayjs(s).diff(dayjs(row.created_at), 'milliseconds');
                    const uploadedInRes = `${CommonFormatter.duration(uploadToImportTime, { truncate: 'seconds' })}`;
                    return `${Formatter.date(s, {
                        format: 'MM/DD/YYYY hh:mmA',
                    })} (${uploadedInRes})`;
                },
            },
            created_at: {
                label: 'Uploaded at',
                enabled: true,
                formatter: (s) => {
                    return Formatter.date(s, {
                        format: 'MM/DD/YYYY hh:mmA',
                    });
                },
                readOnly: true,
            },
        },
        actions: role === 'admin'
            ? [
                {
                    id: 'process',
                    label: 'Process',
                    type: 'iconButton',
                    icon: _jsx(PlayArrow, {}),
                    onClick: (row) => handleRunButtonClick(row),
                },
                {
                    id: 'edit',
                    label: 'Edit',
                    type: 'iconButton',
                    icon: _jsx(Edit, {}),
                    onClick: (row) => {
                        setRowData(row);
                        setShowEdit(true);
                    },
                },
                {
                    id: 'delete',
                    label: 'Delete',
                    type: 'iconButton',
                    icon: _jsx(DeleteOutline, {}),
                    onClick: (row) => {
                        setRowData(row);
                        setShowConfirm(true);
                    },
                },
                {
                    id: 'mark_processed',
                    label: 'Mark as processed',
                    enabled: (row) => row.status === DocumentStatuses.NEW,
                    onClick: (row) => __awaiter(void 0, void 0, void 0, function* () { return yield markProcessed(row); }),
                },
            ]
            : [
                {
                    id: 'edit',
                    label: 'Edit',
                    type: 'iconButton',
                    icon: _jsx(Edit, {}),
                    onClick: (row) => {
                        setRowData(row);
                        setShowEdit(true);
                    },
                },
                {
                    id: 'delete',
                    label: 'Delete',
                    type: 'iconButton',
                    icon: _jsx(DeleteOutline, {}),
                    enabled: (row) => row.status === DocumentStatuses.NEW,
                    onClick: (row) => {
                        setRowData(row);
                        setShowConfirm(true);
                    },
                },
            ],
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            new: {
                id: DocumentStatuses.NEW,
                label: DocumentStatusesLabels[DocumentStatuses.NEW],
                query: {
                    status: DocumentStatuses.NEW,
                },
            },
            processing: {
                id: DocumentStatuses.PROCESSING,
                label: DocumentStatusesLabels[DocumentStatuses.PROCESSING],
                query: {
                    status: DocumentStatuses.PROCESSING,
                },
            },
            pending_review: {
                id: DocumentStatuses.PENDING_REVIEW,
                label: DocumentStatusesLabels[DocumentStatuses.PENDING_REVIEW],
                query: {
                    status: DocumentStatuses.PENDING_REVIEW,
                },
            },
            processed: {
                id: DocumentStatuses.PROCESSED,
                label: DocumentStatusesLabels[DocumentStatuses.PROCESSED],
                query: {
                    status: DocumentStatuses.PROCESSED,
                },
            },
        },
    }), [
        exportData,
        filePathFormatter,
        filters,
        handleRunButtonClick,
        isRiskTag,
        labels,
        markProcessed,
        overrideFilePathFormatter,
        role,
        setRowData,
        setShowConfirm,
        setShowEdit,
        setSync,
    ]);
    return { dataDesc };
};
