import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { isValidJsonString } from 'common/helpers';
import { FiltersOperators } from 'common/globalTypes';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { z } from 'zod';
import { useState } from 'react';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import { FieldTypes } from '@/types';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import { ExpandableTextField } from '@/components/molecules/ExpandableTextField';
const numericSchema = z.string().regex(/^\d*\.?\d*$/, 'Invalid numeric value');
const NumericTextField = ({ fieldMatcher, fieldsMatchers, i, setValue, adornment = '', adornmentPosition = 'end', }) => {
    const [error, setError] = useState(null);
    return (_jsx(TextField, { label: "Value", value: fieldMatcher.value, onChange: (e) => {
            const value = e.target.value;
            const result = numericSchema.safeParse(value);
            if (result.success) {
                setError(null);
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].value = value;
                setValue(newFieldMatchers);
            }
            else {
                setError(result.error.errors[0].message);
            }
        }, error: !!error, helperText: error, InputProps: {
            [`${adornmentPosition}Adornment`]: (_jsx(InputAdornment, { position: adornmentPosition, children: adornment })),
        }, sx: { mr: 1 } }));
};
const fieldTypeComponents = {
    [FieldTypes.DATE]: ({ fieldMatcher, fieldsMatchers, i, setValue }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(BasicDatePicker, { sx: { mr: 1 }, label: "Value", value: fieldMatcher.value, setValue: (value) => {
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].value = dayjs(value).utc().format('YYYY-MM-DD');
                setValue(newFieldMatchers);
            } }) })),
    [FieldTypes.SELECT]: ({ field, fieldMatcher, fieldsMatchers, i, setValue, componentOptions, }) => (_jsx(EnhancedSelect, { label: "Value", options: field.options, multiple: componentOptions.multiple ? componentOptions.multiple : false, value: componentOptions.multiple
            ? field.options.filter((item) => {
                var _a;
                return (_a = fieldMatcher.value) === null || _a === void 0 ? void 0 : _a.includes(typeof item === 'string' ? item : item.id);
            })
            : field.options.find((item) => {
                if (typeof item === 'string')
                    return item === fieldMatcher.value;
                return item.id === fieldMatcher.value;
            }), onChange: (v) => {
            const newFieldMatchers = [...fieldsMatchers];
            if (componentOptions.multiple) {
                newFieldMatchers[i].value = v.map((item) => typeof item === 'string' ? item : item.id);
            }
            else {
                newFieldMatchers[i].value = typeof v === 'string' ? v : v.id;
            }
            setValue(newFieldMatchers);
        }, sx: { mr: 1 } })),
    [FieldTypes.PERCENTAGE]: ({ fieldMatcher, fieldsMatchers, i, setValue }) => (_jsx(NumericTextField, { fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue, adornment: "%" })),
    [FieldTypes.CURRENCY]: ({ fieldMatcher, fieldsMatchers, i, setValue }) => (_jsx(NumericTextField, { fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue, adornment: "$", adornmentPosition: "start" })),
    [FieldTypes.INTEGER]: ({ fieldMatcher, fieldsMatchers, i, setValue }) => (_jsx(NumericTextField, { fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue })),
    // Add more field types and their corresponding components here
};
const DefaultFieldComponent = ({ fieldMatcher, fieldsMatchers, i, setValue, }) => (_jsx(ExpandableTextField, { label: "Value", defaultValue: fieldMatcher.value, onChange: (value) => {
        const newFieldMatchers = [...fieldsMatchers];
        newFieldMatchers[i].value = value;
        setValue(newFieldMatchers);
    }, error: ['ncontainedin', 'containedin'].includes(fieldMatcher.op) &&
        !isValidJsonString(fieldMatcher.value) }));
const FieldMatcherComponent = ({ fields, fieldMatcher, fieldsMatchers, i, setValue, }) => {
    const field = fields.find((f) => f.id === (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.field));
    let FieldComponent = fieldTypeComponents[field === null || field === void 0 ? void 0 : field.type] || DefaultFieldComponent;
    if ((fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === FiltersOperators.WITHIN_ONE_YEAR ||
        (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === FiltersOperators.AT_LEAST_ONE_YEAR) {
        FieldComponent = fieldTypeComponents[FieldTypes.SELECT];
    }
    const componentOptions = {
        multiple: false,
    };
    if ((fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === FiltersOperators.CONTAINEDIN ||
        (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === FiltersOperators.NCONTAINEDIN) {
        componentOptions.multiple = true;
    }
    if ((fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === FiltersOperators.IS_EMPTY ||
        (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === FiltersOperators.IS_NOT_EMPTY) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(FieldComponent, { field: field, fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue, componentOptions: componentOptions }));
};
export default FieldMatcherComponent;
