var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Autocomplete, TextField, CircularProgress, Typography, FormControlLabel, Radio, RadioGroup, Alert, } from '@mui/material';
import { useEffect, useState } from 'react';
import { GroupingDTOSchema } from 'common/dto/data_processing/grouping';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
import StatementCard from './StatementCard';
export default function GroupingEdit({ open, statements, onClose, refetch, }) {
    const { showSnackbar } = useSnackbar();
    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [action, setAction] = useState('group');
    const handleGroup = () => __awaiter(this, void 0, void 0, function* () {
        setConfirmOpen(false);
        try {
            setLoading(true);
            const requestData = GroupingDTOSchema.parse({
                isSync: true,
                isManual: true,
                statementIds: statements.map((s) => s.id),
                masterId: selected === null || selected === void 0 ? void 0 : selected.id,
                actions: [action],
            });
            const res = yield fetch(`${process.env.REACT_APP_API}/api/data_processing/grouping`, {
                method: 'POST',
                headers: yield API.getHeaders(),
                body: JSON.stringify(requestData),
            });
            yield res.json();
            if (res.status === 200) {
                showSnackbar('Grouping successful', 'success');
            }
            if (refetch) {
                refetch();
            }
            handleClose();
        }
        catch (_error) {
            if (_error.name === 'ZodError') {
                showSnackbar(Object.entries(_error.flatten().fieldErrors)
                    .map(([_key, value]) => `${value}`)
                    .join('\n'), 'error');
            }
            else {
                showSnackbar('Error grouping records', 'error');
            }
        }
        finally {
            setLoading(false);
        }
    });
    const handleClose = () => {
        onClose();
        setSelected(null);
        setConfirmOpen(false);
    };
    useEffect(() => {
        var _a;
        setOptions((_a = statements === null || statements === void 0 ? void 0 : statements.map((r) => (Object.assign({ id: r.id, label: `PolicyNumber: ${r.policy_id} - EffDate: ${r.effective_date}`, str_id: r.str_id }, r)))) !== null && _a !== void 0 ? _a : []);
    }, [statements]);
    const recordsWithChildren = statements.filter((r) => { var _a; return r.id !== (selected === null || selected === void 0 ? void 0 : selected.id) && ((_a = r.children_data) === null || _a === void 0 ? void 0 : _a.length) > 0; });
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, { open: open, onClose: onClose, children: [_jsx(DialogTitle, { children: "Group records" }), _jsx("div", {}), _jsxs(DialogContent, { sx: {
                            width: '400px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: 'stretch',
                        }, children: [_jsx(Autocomplete, { value: selected, onChange: (_e, v) => setSelected(v), options: options, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Select the primary record" }))), renderOption: (props, option) => {
                                    const { key: _key } = props, optionProps = __rest(props, ["key"]);
                                    return (_jsx(StatementCard, Object.assign({ statement: option }, optionProps), option.id));
                                } }), _jsx(RadioGroup, { value: action, onChange: (e) => setAction(e.target.value), children: _jsx(FormControlLabel, { value: "group", control: _jsx(Radio, {}), label: "Group" }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { onClick: () => setConfirmOpen(true), disabled: loading || !selected, variant: "contained", children: loading ? _jsx(CircularProgress, { size: 24 }) : 'Group' })] })] }), _jsxs(Dialog, { open: confirmOpen, onClose: () => setConfirmOpen(false), children: [_jsx(DialogTitle, { children: "Confirm grouping" }), _jsxs(DialogContent, { children: [_jsxs(Typography, { children: ["Are you sure you want to group the selected statements with master statement (Policy: ", selected === null || selected === void 0 ? void 0 : selected.policy_id, ")?"] }), _jsx("div", { style: { height: 10 } }), selected && _jsx(StatementCard, { statement: selected }), _jsx("div", { style: { height: 10 } }), recordsWithChildren.length > 0 && (_jsx(_Fragment, { children: recordsWithChildren.map((r) => (_jsx(Alert, { severity: "warning", sx: { mb: 2 }, children: _jsxs(Typography, { children: ["Policy ", r.policy_id, " is a primary record for an existing group of commissions. Grouping it as a linked record of Policy", selected === null || selected === void 0 ? void 0 : selected.policy_id, " will unset any existing linked commissions for Policy ", r.policy_id, "."] }) }))) }))] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: () => setConfirmOpen(false), children: "Cancel" }), _jsx(Button, { onClick: handleGroup, color: "primary", variant: "contained", children: "Confirm" })] })] })] }));
}
