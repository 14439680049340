var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, ButtonGroup, CircularProgress, IconButton, } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { memo } from 'react';
import MoreMenu from '@/components/molecules/MoreMenu';
export const FloatingActions = memo(({ rowData, actionLoading, onEdit, actions, actionsEnabled, getSetActionLoadingByRowId, }) => {
    var _a;
    const moreActions = (actions === null || actions === void 0 ? void 0 : actions.filter((action) => !['button', 'iconButton', 'icon', 'custom'].includes(action.type) &&
        (!action.enabled ||
            (typeof action.enabled === 'function'
                ? action.enabled(rowData)
                : action.enabled)))) || [];
    return actionLoading[rowData.id] ? (_jsx(Box, { sx: {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            right: 20,
            zIndex: 2,
        }, children: _jsx(Box, { sx: {
                borderRadius: 8,
                backdropFilter: 'blur(8px)',
                display: 'flex',
            }, children: _jsx(CircularProgress, { disableShrink: true, size: 22, thickness: 4 }) }) })) : (_jsxs(ButtonGroup, { className: "action-buttons", sx: {
            display: 'none',
            position: 'absolute',
            right: 20,
            zIndex: 2,
            background: 'white',
        }, variant: "contained", children: [typeof onEdit === 'function' && (_jsx(IconButton, { onClick: () => {
                    onEdit(rowData);
                }, children: _jsx(Edit, {}) })), !!(actions === null || actions === void 0 ? void 0 : actions.length) && actionsEnabled(rowData) && (_jsxs(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                }, children: [(_a = actions === null || actions === void 0 ? void 0 : actions.filter((action) => ['button', 'iconButton', 'icon', 'custom'].includes(action.type) &&
                        (!action.enabled ||
                            (typeof action.enabled === 'function'
                                ? action.enabled(rowData)
                                : action.enabled)))) === null || _a === void 0 ? void 0 : _a.map((action) => {
                        var _a;
                        const setRowActionLoading = getSetActionLoadingByRowId(rowData.id);
                        switch (action.type) {
                            case 'button':
                                return (_jsx(Button, { variant: "text", onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                        setRowActionLoading(true);
                                        yield action.onClick(rowData, e);
                                        setRowActionLoading(false);
                                    }), children: action.label }, action.label));
                            case 'iconButton':
                                return (_jsx(IconButton, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                        setRowActionLoading(true);
                                        yield action.onClick(rowData, e);
                                        setRowActionLoading(false);
                                    }), children: action.icon }, action.label));
                            case 'icon':
                                return (_jsx(Box, { sx: {
                                        height: 32,
                                        display: 'flex',
                                    }, children: action.icon }, action.label));
                            case 'custom':
                                return (_jsx(Box, { sx: {
                                        height: 32,
                                        display: 'flex',
                                    }, children: (_a = action.getComponent) === null || _a === void 0 ? void 0 : _a.call(action, rowData) }, action.id));
                            default:
                                return null;
                        }
                    }), (moreActions === null || moreActions === void 0 ? void 0 : moreActions.length) > 0 && (_jsx(MoreMenu, { actions: moreActions, setActionLoading: getSetActionLoadingByRowId(rowData.id), data: rowData, sx: {} }))] }))] }));
});
