import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip, Typography } from '@mui/material';
import { AccountIds } from 'common/constants';
import Formatter from 'common/Formatter';
import { formatCurrency } from 'common/helpers/formatCurrency';
import { MaterialReactTable, MRT_TablePagination, } from 'material-react-table';
import { useMemo } from 'react';
import ExpandableData from '@/components/molecules/ExpandableData';
import { useDragToScroll } from '@/hooks/useDragToScroll';
import { requiresFintaryAdmin } from '@/services/helpers';
import { useAccountStore } from '@/store';
import { CHAR_WIDTH } from '../../constants';
import { RowsPerPage } from '../constants';
import { Cell } from './Cell';
import { FloatingActions } from './FloatingActions';
export const NewTable = ({ data, columns, isLoading, rowKey = 'id', dynamicSelects, pagination, selectedRows, setSelected, setSelectedData, sorting, actionLoading, onEdit, actions, actionsEnabled, getSetActionLoadingByRowId, showTotals, sums, }) => {
    const { refContainer } = useDragToScroll();
    const { selectedAccount } = useAccountStore();
    const _columns = useMemo(() => {
        const cols = columns.map((column, index) => {
            return {
                accessorKey: column.id,
                header: '',
                enableColumnActions: false,
                footer: '',
                grow: column.width ? 1 : 0,
                minSize: 40,
                maxSize: 1000,
                size: column.width || 160,
                Footer: () => {
                    if (index === 0 && showTotals) {
                        return (_jsx(Typography, { fontSize: 14, color: "black", fontWeight: "bold", children: "Totals" }));
                    }
                    if (!sums[index] || !showTotals)
                        return null;
                    return typeof sums[index] === 'object' ? (_jsx(Box, { sx: { pb: 2 }, children: _jsx(ExpandableData, { data: Object.entries(sums[index]).map(([k, v]) => {
                                var _a, _b;
                                return `${Formatter.contact((_b = (_a = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.contacts) === null || _a === void 0 ? void 0 : _a.find((c) => c.str_id === k)) !== null && _b !== void 0 ? _b : {}, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId })}: ${formatCurrency(v)}`;
                            }), header: column }) })) : (_jsx(Typography, { sx: {
                            fontSize: 14,
                            color: 'black',
                        }, children: formatCurrency(sums[index]) }));
                },
                muiTableBodyCellProps: {
                    sx: Object.assign({}, column.style),
                },
                muiTableHeadCellProps: {
                    sx: Object.assign(Object.assign({}, column.style), { py: 1, fontWeight: 500, '& .MuiBadge-root': {
                            visibility: column.id === sorting.orderBy ? 'visible' : 'hidden',
                        }, ':hover': {
                            '& .MuiBadge-root': {
                                visibility: 'visible',
                            },
                        } }),
                },
                Header: () => {
                    const showToltip = (column.width || 0) <= column.label.length * CHAR_WIDTH + 24; // 24 is sort icon width
                    const content = (_jsxs(Box, { sx: {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }, children: [column.label, column.infoIcon && ' ⓘ', requiresFintaryAdmin(column.access) && ' 🔒'] }));
                    if (column.description)
                        return (_jsx(Tooltip, { title: _jsx(Box, { children: _jsx("div", { children: column.description }) }), placement: "top", children: content }));
                    if (showToltip) {
                        return (_jsx(Tooltip, { title: _jsx(Box, { children: _jsx("div", { children: column.label }) }), placement: "top", children: content }));
                    }
                    return content;
                },
                Cell: ({ row }) => {
                    return (_jsx(Cell, { column: column, rowData: row.original, dynamicSelects: dynamicSelects }));
                },
                enableSorting: !column.disableSort,
            };
        });
        cols.push({
            accessorKey: 'mrt-row-actions',
            header: '',
            size: 0,
            minSize: 0,
            maxSize: 0,
            enableColumnActions: false,
            enableSorting: false,
            Header: () => _jsx(Box, {}),
            Cell: ({ row: tableRow }) => {
                const row = tableRow.original;
                return (_jsx(FloatingActions, { actionLoading: actionLoading, onEdit: onEdit, actions: actions, actionsEnabled: actionsEnabled, getSetActionLoadingByRowId: getSetActionLoadingByRowId, rowData: row }));
            },
        });
        return cols;
    }, [
        actionLoading,
        actions,
        actionsEnabled,
        columns,
        dynamicSelects,
        getSetActionLoadingByRowId,
        onEdit,
        selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
        showTotals,
        sorting.orderBy,
        sums,
    ]);
    const rowSelection = useMemo(() => {
        return selectedRows.reduce((acc, id) => {
            acc[id] = true;
            return acc;
        }, {});
    }, [selectedRows]);
    const _pagination = useMemo(() => {
        return {
            pageIndex: pagination.page,
            pageSize: pagination.rowsPerPage,
        };
    }, [pagination.page, pagination.rowsPerPage]);
    const handlePagination = (updater) => {
        const currentState = {
            pageIndex: pagination.page,
            pageSize: pagination.rowsPerPage,
        };
        const newState = updater(currentState);
        if (newState.pageIndex !== pagination.page)
            pagination.onPageChange(null, newState.pageIndex);
        if (newState.pageSize !== pagination.rowsPerPage)
            pagination.onRowsPerPageChange({ target: { value: newState.pageSize } });
    };
    const handleSelection = (updater) => {
        const currentSorting = selectedRows.reduce((acc, id) => {
            acc[id] = true;
            return acc;
        }, {});
        const newState = updater(currentSorting);
        const newSorting = [];
        for (const key in newState) {
            if (newState[key]) {
                const _key = JSON.parse(key); // Convert back to real type
                newSorting.push(_key);
            }
        }
        setSelected(newSorting);
        setSelectedData(newSorting.map((id) => data.find((v) => v.id === id)));
    };
    const handleSorting = (updater) => {
        const currentSorting = [
            { id: sorting.orderBy, desc: sorting.order === 'desc' },
        ];
        const newState = updater(currentSorting);
        sorting.setOrder(newState[0].desc ? 'desc' : 'asc');
        sorting.setOrderBy(newState[0].id);
    };
    if (process.env.NODE_ENV === 'development') {
        console.log('NewTable render');
    }
    return (_jsx(MaterialReactTable, { enableRowNumbers: AccountIds.TRANSGLOBAL === (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId), 
        // === Sorting
        manualSorting: true, onSortingChange: handleSorting, 
        // === Virtualization
        enableRowVirtualization: true, rowVirtualizerOptions: {
            overscan: 10,
        }, 
        // === Sticky headers and footers
        enableFilters: false, enableStickyHeader: true, 
        // MaterialReactTable always convert id to string, even number
        // So using JSON.stringify to keep its type (convert back in handleSelection)
        getRowId: (v) => JSON.stringify(v.id || v.str_id || v[rowKey]), columns: _columns, data: data || [], rowCount: pagination.count, enableGlobalFilter: false, initialState: {
            density: 'compact',
        }, displayColumnDefOptions: {
            'mrt-row-select': {
                size: 28,
            },
            'mrt-row-numbers': {
                size: 28,
            },
        }, state: {
            isLoading,
            pagination: _pagination,
            sorting: [{ id: sorting.orderBy, desc: sorting.order === 'desc' }],
            columnPinning: {
                left: [
                    'mrt-row-select',
                    'mrt-row-numbers',
                    ...columns.filter((v) => v.sticky === 'left').map((v) => v.id),
                ],
                right: ['mrt-row-actions'],
            },
            rowSelection,
        }, muiTablePaperProps: {
            elevation: 0,
            sx: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
        }, muiTableContainerProps: {
            sx: {
                flex: 1,
                maxHeight: 'calc(100vh - 64px - 109px)',
            }, // 64px = AppBar, 109px = TopToolbar
            ref: refContainer,
        }, muiTableFooterProps: {
            sx: {
                '& .MuiTableCell-footer[data-pinned="true"]': {
                    boxShadow: 'rgba(0, 0, 0, 0.1) 4px 0px 8px',
                },
                '& .MuiTableCell-footer[data-pinned="true"]:before': {
                    boxShadow: 'none',
                    backgroundColor: 'white',
                },
            },
        }, muiTableHeadProps: () => {
            return {
                sx: {
                    '& .MuiTableCell-head[data-pinned="true"]': {
                        boxShadow: 'rgba(0, 0, 0, 0.1) 4px 0px 8px',
                    },
                    '& .MuiTableCell-head[data-pinned="true"]:before': {
                        boxShadow: 'none',
                        backgroundColor: 'white',
                    },
                },
            };
        }, muiTableBodyRowProps: ({}) => {
            return {
                sx: {
                    '& td.MuiTableCell-root[data-pinned="true"]': {
                        boxShadow: 'rgba(0, 0, 0, 0.1) 4px 0px 8px',
                        '&:last-child': {
                            color: 'red !important',
                        },
                    },
                    '& td.MuiTableCell-root:before': {
                        backgroundColor: 'white',
                        boxShadow: 'none',
                    },
                    '& .MuiTableCell-root:after': {
                        backgroundColor: 'rgba(33, 150, 243, 0.12)',
                    },
                    '&.MuiTableRow-root:hover': {
                        '& .MuiTableCell-root:after': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                        '& .action-buttons': {
                            display: 'flex',
                        },
                    },
                },
            };
        }, muiTableBodyCellProps: ({ column }) => {
            if (column.id === 'mrt-row-actions')
                return {
                    sx: {
                        overflow: 'visible',
                        '&.MuiTableCell-body:before': {
                            boxShadow: 'none',
                        },
                    },
                };
            return {};
        }, manualPagination: true, muiPaginationProps: {
            rowsPerPageOptions: RowsPerPage,
        }, onRowSelectionChange: handleSelection, enableRowSelection: true, onPaginationChange: handlePagination, enableColumnPinning: true, enableTableFooter: true, renderBottomToolbar: ({ table }) => {
            return (_jsx(Box, { sx: {
                    background: 'white',
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    '& .MuiTablePagination-root': {
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                    },
                }, children: _jsx(MRT_TablePagination, { table: table }) }));
        }, enableTopToolbar: false }));
};
