import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close as CloseIcon, Launch } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, Typography, } from '@mui/material';
import { Link } from 'react-router-dom';
import Formatter from 'common/Formatter';
import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useAccountStore } from '@/store';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import { FieldTypes } from '@/types';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const ParentsHierarchyAdd = ({ data, field, setter, syncedFields, dynamicSelects, }) => {
    var _a;
    const { selectedAccount } = useAccountStore();
    const parentsHierarchy = (_a = data === null || data === void 0 ? void 0 : data.parent_relationships) !== null && _a !== void 0 ? _a : [];
    const options = useMemo(() => {
        return ((dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.map((option) => {
            return Object.assign(Object.assign({}, option), { label: Formatter.contact(option, {
                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                }) });
        })) || []);
    }, [dynamicSelects, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(EnhancedSelect, { sx: { my: 1 }, label: "Select", multiple: true, enableSearch: true, disableAllOption: true, enableSelectAllSearchResult: false, options: options, value: parentsHierarchy.map((item) => item.parent), labelKey: "label", onChange: (v) => {
                    const updatedParentRelationships = v
                        .filter((value) => !!value.id)
                        .map((value) => {
                        return Object.assign({ parent: value, id: nanoid(), start_date: null, end_date: null }, parentsHierarchy.find((item) => item.parent.id === value.id));
                    });
                    setter(Object.assign(Object.assign({}, data), { parent_relationships: updatedParentRelationships }));
                } }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        mt: 0.5,
                        pl: 1,
                        pt: 0.5,
                        pb: 0,
                        display: (parentsHierarchy === null || parentsHierarchy === void 0 ? void 0 : parentsHierarchy.length) ? 'flex' : 'none',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                        flexWrap: 'wrap',
                    }, children: parentsHierarchy.map((parent, index) => {
                        var _a;
                        return (_jsxs(Box, { sx: {
                                m: 0.5,
                                p: 1,
                                borderStyle: 'solid',
                                borderColor: 'silver',
                                borderWidth: 1,
                                borderRadius: 4,
                                display: 'inline-block',
                                backgroundColor: '#2196f30a',
                            }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: Formatter.contact(parent.parent, {
                                                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                            }) }), _jsxs("span", { children: [_jsx(IconButton, { component: Link, to: `/agents/list?id=${parent.parent.str_id}`, target: "_blank", children: _jsx(Launch, {}) }), _jsx(IconButton, { onClick: () => {
                                                        setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.filter((item) => item.id !== parent.id) }));
                                                    }, children: _jsx(CloseIcon, {}) })] })] }), _jsx(Box, { sx: { mt: 0.5 }, children: _jsx(BasicDateRangePicker, { range: {
                                            startDate: parent.start_date
                                                ? dayjs.utc(parent.start_date)
                                                : null,
                                            startDateLabel: 'Start date',
                                            endDate: parent.end_date
                                                ? dayjs.utc(parent.end_date)
                                                : null,
                                            endDateLabel: 'End date',
                                            endAdornmentStartDate: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: parent.sync_id, fieldId: "start_date", fieldType: FieldTypes.DATE, data: parent, onChange: (newOverrideFields) => {
                                                    setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                } })),
                                            endAdornmentEndDate: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: parent.sync_id, fieldId: "end_date", fieldType: FieldTypes.DATE, data: parent, onChange: (newOverrideFields) => {
                                                    setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                } })),
                                        }, onChange: ({ startDate, endDate }) => {
                                            setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                    ? Object.assign(Object.assign({}, item), { start_date: startDate, end_date: endDate }) : item) }));
                                        }, width: parent.sync_id ? 168 : 140, my: 0.5 }) }), _jsx(Box, { children: _jsx(TextField, { label: "Split percentage", value: (_a = parent.split_percentage) !== null && _a !== void 0 ? _a : '', onChange: (e) => {
                                            setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                    ? Object.assign(Object.assign({}, item), { split_percentage: e.target.value }) : item) }));
                                        }, InputProps: {
                                            endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                        }, sx: { my: 0.5, width: '100%' } }) })] }, `${parent.id}-${index}`));
                    }) }) }, `${field.id}-box`)] }));
};
export default ParentsHierarchyAdd;
